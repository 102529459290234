import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { showToast } from 'src/utils/common';
import { OtptracuuAction } from 'src/actions/otptracuu.action';

import { isGrantPermission } from 'src/utils/utils';

const CustomerLookupPackageHistory2 = (props) => {
  const dispatch = useDispatch()
  const { info, permissions, role } = useSelector(state => state.profile)
  const { pkg } = useSelector(state => state.lookup)
  const [cusInfo, setCusInfo] = useState({});
  const [clickTraCuu, setClickTraCuu] = useState(false);
  const [values, setValues] = useState({
    phone: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = async () => {
    //dispatch(LookupAction.lookupPackage(values.phone))

    var phone = (values.phone).substring((values.phone).length - 9)

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuChuKyGoiCuocHienTaiTuData(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo(result.data);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
        showToast('error', 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(LookupAction.clearLookup())
    }
  }, [])

  return (
    <form {...props}>
      {isGrantPermission(permissions, ['tra_cuu_4']) && <Card>
        <CardHeader
          title="#4 TRA CỨU CHU KỲ GÓI CƯỚC HIỆN TẠI (TỪ DATA)"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            onChange={handleChange}
            required
            name='phone'
            value={values.phone}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          {isGrantPermission(permissions, ['tra_cuu_4']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            disabled={clickTraCuu}
          >
            Tra cứu
          </Button>}
        </Box>
        {cusInfo && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  Kết quả
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.so_dien_thoai}
                </TableCell>
              </TableRow>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Gói cước hiện tại
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.goi_cuoc}
                </TableCell>
              </TableRow>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Số chu kỳ miễn phí còn lại
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.so_chu_ky_mien_phi_con_lai}
                </TableCell>
              </TableRow>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Ngày đăng ký
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.ngay_dang_ky}
                </TableCell>
              </TableRow>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Ngày hết hạn
                  </Typography>
                </TableCell>
                <TableCell style={{ alignItems: 'center' }}>
                  {cusInfo.ngay_het_han}
                </TableCell>
              </TableRow>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Chu kỳ hiện tại
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.chu_ky_hien_tai}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>}
      </Card>}
    </form>
  );
};

export default CustomerLookupPackageHistory2;
