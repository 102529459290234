import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { showToast } from 'src/utils/common';
import { OtptracuuAction } from 'src/actions/otptracuu.action';
import { isGrantPermission } from 'src/utils/utils';

const CustomerLookupPackageHistory = (props) => {
  const dispatch = useDispatch()
  const { pkg } = useSelector(state => state.lookup)
  const [cusInfo, setCusInfo] = useState({});
  const [lichSuGoiCuoc, setLichSuGoiCuoc] = useState([]);
  const [clickTraCuu, setClickTraCuu] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)
  const [values, setValues] = useState({
    phone: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = async () => {
    //dispatch(LookupAction.lookupPackage(values.phone))

    var phone = (values.phone).substring((values.phone).length - 9)

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuNgayHetHanGoiCuocHienTai(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo(result.outputData);
        console.log(cusInfo.goi_cuoc);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  const traCuuLichSuGoiCuoc = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuLichSuGoiCuoc(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setLichSuGoiCuoc(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(LookupAction.clearLookup())
    }
  }, [])

  return (
    <form {...props}>
      {isGrantPermission(permissions, ['tra_cuu_3', 'tra_cuu_3_lich_su_goi_cuoc']) && <Card>
        <CardHeader
          title="#3 TRA CỨU NGÀY HẾT HẠN GÓI CƯỚC HIỆN TẠI"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            onChange={handleChange}
            required
            name='phone'
            value={values.phone}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          {isGrantPermission(permissions, ['tra_cuu_3']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            disabled={clickTraCuu}
          >
            Tra cứu
          </Button>}
        </Box>
        <Divider />
        {cusInfo && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  DANH SÁCH GÓI CƯỚC
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell colSpan={5}>
                    Các gói cước hiện tại của thuê bao {values.phone}
                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell style={{fontWeight: 'bold'}}>
                  Tên gói cước
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Ngày hết hạn
                </TableCell>
              </TableRow>

              {cusInfo && typeof cusInfo.goi_cuoc !== 'undefined' && cusInfo.goi_cuoc && (cusInfo.goi_cuoc).map((pkg) => (
                <><TableRow
                  hover
                >
                  <TableCell>
                    {pkg.ten_goi_cuoc}
                  </TableCell>
                  <TableCell>
                    {pkg.ngay_het_han_goi_cuoc}
                  </TableCell>
                </TableRow></>
              ))}

              <TableRow
                hover
              >
                <TableCell>
                  {isGrantPermission(permissions, ['tra_cuu_3_lich_su_goi_cuoc']) && <Button
                    color="primary"
                    variant="contained"
                    onClick={traCuuLichSuGoiCuoc}
                    disabled={clickTraCuu}
                  >
                    Tra cứu LS gói cước
                  </Button>}
                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell style={{fontWeight: 'bold'}}>
                  MÃ DV
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  DỊCH VỤ
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  GÓI
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  THAO TÁC
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  NGÀY BẮT ĐẦU
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  NGÀY KẾT THÚC
                </TableCell>
              </TableRow>

              {lichSuGoiCuoc && (lichSuGoiCuoc).map((ls) => (
                <><TableRow
                  hover
                >
                  <TableCell>
                    {ls.service_code}
                  </TableCell>
                  <TableCell>
                    {ls.dichvu}
                  </TableCell>
                  <TableCell>
                    {ls.goi}
                  </TableCell>
                  <TableCell>
                    {ls.thao_tac}
                  </TableCell>
                  <TableCell>
                    {ls.batdau}
                  </TableCell>
                  <TableCell>
                    {ls.ketthuc}
                  </TableCell>
                </TableRow></>
              ))}
            </TableBody>
          </Table>
        </Box>}
      </Card>}
    </form>
  );
};

export default CustomerLookupPackageHistory;
