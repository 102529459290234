import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { DailyAction } from 'src/actions/daily.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getDailyStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

const DailyLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedDailyIds, setSelectedDailyIds] = useState([]);
  const { items, total, filter } = useSelector(state => state.daily);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(DailyAction.updateFilter(payload));
    dispatch(DailyAction.list(page, limit))
  }, [page])

  const handleSelectAll = (event) => {
    let newSelectedDailyIds;

    if (event.target.checked) {
      newSelectedDailyIds = items.map((daily) => daily.BRANCH_ID);
    } else {
      newSelectedDailyIds = [];
    }

    setSelectedDailyIds(newSelectedDailyIds);
  };

  const handleSelectOne = (event, BRANCH_ID) => {
    const selectedIndex = selectedDailyIds.indexOf(BRANCH_ID);
    let newSelectedDailyIds = [];

    if (selectedIndex === -1) {
      newSelectedDailyIds = newSelectedDailyIds.concat(selectedDailyIds, BRANCH_ID);
    } else if (selectedIndex === 0) {
      newSelectedDailyIds = newSelectedDailyIds.concat(selectedDailyIds.slice(1));
    } else if (selectedIndex === selectedDailyIds.length - 1) {
      newSelectedDailyIds = newSelectedDailyIds.concat(selectedDailyIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedDailyIds = newSelectedDailyIds.concat(
        selectedDailyIds.slice(0, selectedIndex),
        selectedDailyIds.slice(selectedIndex + 1)
      );
    }

    setSelectedDailyIds(newSelectedDailyIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, daily) => {
    setSelected(daily);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(DailyAction.del(selected.BRANCH_ID))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (BRANCH_ID) => {
    dispatch(DailyAction.findOne(BRANCH_ID));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedDailyIds.length === items.length}
                    color="primary"
                    indeterminate={
                      selectedDailyIds.length > 0
                      && selectedDailyIds.length < items.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  ID chi nhánh
                </TableCell>
                <TableCell>
                  Tên chi nhánh
                </TableCell>
                <TableCell>
                  Mã chi nhánh
                </TableCell>
                <TableCell>
                  Mã GD chi nhánh
                </TableCell>
                <TableCell>
                  SĐT bàn chi nhánh
                </TableCell>
                <TableCell>
                  SĐT DĐ chi nhánh
                </TableCell>
                <TableCell>
                  Username hệ thống
                </TableCell>
                <TableCell>
                  Là chi nhánh?
                </TableCell>
                <TableCell>
                  Là mã để mời gói hôm sau?
                </TableCell>
                <TableCell>
                  Chức năng
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((daily) => (
                <TableRow
                  hover
                  key={daily.BRANCH_ID}
                  selected={selectedDailyIds.indexOf(daily.BRANCH_ID) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedDailyIds.indexOf(daily.BRANCH_ID) !== -1}
                      onChange={(event) => handleSelectOne(event, daily.BRANCH_ID)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {daily.BRANCH_ID}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {daily.BRANCH_NAME}
                  </TableCell>
                  <TableCell>
                    {daily.BRANCH_CODE}
                  </TableCell>
                  <TableCell>
                    {daily.BRANCH_SALES_CODE}
                  </TableCell>
                  <TableCell>
                    {daily.BRANCH_TEL}
                  </TableCell>
                  <TableCell>
                    {daily.BRANCH_MOBILE}
                  </TableCell>
                  <TableCell>
                    {daily.username_he_thong}
                  </TableCell>
                  <TableCell style={{color: daily.is_branch == 1 ? 'green' : 'red'}}>
                    {daily.is_branch == 1 ? 'CÓ' : 'KHÔNG'}
                  </TableCell>
                  <TableCell style={{color: daily.de_moi_goi_hom_sau == 1 ? 'green' : 'red'}}>
                    {daily.de_moi_goi_hom_sau == 1 ? 'CÓ' : 'KHÔNG'}
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => onSelected(daily.BRANCH_ID)}>
                      <Edit3Icon color='green' />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, daily)}>
                      <Trash2Icon color='red' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.BRANCH_ID} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default DailyLookupDetail;
