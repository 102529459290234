import {
  REPORT_9084_QUERY,
  REPORT_9084_SUCCESS,
  REPORT_9084new_QUERY,
  REPORT_9084new_SUCCESS,
  REPORT_9332_QUERY,
  REPORT_9332_SUCCESS,
} from 'src/actions/report.acction';

const initState = {
  query9332: {},
  items9332: [],
  total9332: 0,
  items9084: [],
  total9084: 0,
  items9084new: [],
  total9084new: 0,
}

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case REPORT_9332_QUERY: {
      return {
        ...state,
        query9332: action.data,
      }
    }

    case REPORT_9084_QUERY: {
      return {
        ...state,
        query9084: action.data,
      }
    }

    case REPORT_9084new_QUERY: {
      return {
        ...state,
        query9084new: action.data,
      }
    }

    case REPORT_9332_SUCCESS: {
      console.log("REPORT_9332_SUCCESS: ", action.data.items)
      return {
        ...state,
        items9332: action.data.items,
        total9332: action.data.total,
      }
    }

    case REPORT_9084_SUCCESS: {
      return {
        ...state,
        items9084: action.data.items,
        total9084: action.data.total,
      }
    }

    case REPORT_9084new_SUCCESS: {
      return {
        ...state,
        items9084new: action.data.items,
        total9084new: action.data.total,
      }
    }

    default: {
      return state;
    }
  }
}
export default reportReducer