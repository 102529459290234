import { Helmet } from 'react-helmet';
import SystemaccountDetail
  from 'src/components/systemaccount-detail/SystemaccountDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import SystemaccountListToolbar from '../components/systemaccount-detail/SystemaccountToolbar';

const Systemaccount = () => (
  <>
    <Helmet>
      <title>Tài khoản hệ thống</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <SystemaccountListToolbar />
        <Box sx={{ pt: 3 }}>
          <SystemaccountDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Systemaccount;
