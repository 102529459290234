import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { QuetdataAction } from 'src/actions/quetdata.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getQuetdataStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { element } from 'prop-types';
import io from 'socket.io-client';

const socket = io("https://sys.dvmobifone.com", { rejectUnauthorized: false })

// /** MAIN CONFIG **/
// const VAS_CLIENT_ID = "PGeyhibEDXUFliwERbD6MbOBcF7QJb1AexX2tyhLbFPkSlg6PPUKABgK0PLZtybAzMTbOsd4f4v8cXJSEyzkoD9a14vzzjVOIIRn"
// /** =========== **/

// socket.emit("vas-client", VAS_CLIENT_ID)
// socket.on("connect", () => {
//   console.log("Đã kết nối với máy chủ VAS")
// })

// socket.on("disconnect", () => {
//   console.log("Mất kết nối với máy chủ VAS")
// })

const QuetdataLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedQuetdataIds, setSelectedQuetdataIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const { items, total } = useSelector(state => state.quetdata);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { accessToken, expiresAt } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(QuetdataAction.list(page, limit))
  }, [page])

  const handleSelectAll = (event) => {
    let newSelectedQuetdataIds;

    if (event.target.checked) {
      newSelectedQuetdataIds = items.map((quetdata) => quetdata.id);
    } else {
      newSelectedQuetdataIds = [];
    }

    setSelectedQuetdataIds(newSelectedQuetdataIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedQuetdataIds.indexOf(id);
    let newSelectedQuetdataIds = [];

    if (selectedIndex === -1) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(selectedQuetdataIds, id);
    } else if (selectedIndex === 0) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(selectedQuetdataIds.slice(1));
    } else if (selectedIndex === selectedQuetdataIds.length - 1) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(selectedQuetdataIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedQuetdataIds = newSelectedQuetdataIds.concat(
        selectedQuetdataIds.slice(0, selectedIndex),
        selectedQuetdataIds.slice(selectedIndex + 1)
      );
    }

    setSelectedQuetdataIds(newSelectedQuetdataIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, quetdata) => {
    setSelected(quetdata);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(QuetdataAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(QuetdataAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dungQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      socket.emit('dungQuetVkx2hAzf6mpwQ9RwbMMk4Q0NWCjVg2ZXWC1Gn6pZVWMwgOPkatfFn8kTRDvYcz13gjoPFHZtz2Bx2zux5hIegleeULFOam0TgPfq', {token: accessToken, FileName: FileName});
      alert("Đã yêu cầu dừng quét \"" + FileName + "\"")
      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const batDauQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      socket.emit('batDauQuetmMkS7PM11h3319qmbFyaYMHM6ph1WAmTQ1VetxGsiSBQ9T6hdTu7V5i4hfTh4aeYrE6svgzLqp1ht4YFUAeUb4ofhMRJuLHCinoF', {token: accessToken, FileName: FileName});
      alert("Bắt đầu quét \"" + FileName + "\"")
      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  const xuatSoDaQuet = async (FileName) => {
    console.log(FileName)
    dispatch(AppAction.appLoading(true))
    try {
      const response = await dispatch(QuetdataAction.findAllByFileName({"FileName": FileName}));

      const data = [
        [
          '#', 
          'Số điện thoại', 
          'Loại thuê bao', 
          'Gói cước', 
          'Ngày đăng ký', 
          'Ngày hết hạn', 
          'Chu kỳ hiện tại', 
          'Trạng thái',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && response.length > 0) {
        var count = 1;
        response.forEach((element) => {
          data.push([
            count,
            element.PhoneNumber,
            element.NumberType,
            element.NumberCurrentPackage,
            element.NumberPackageRegisterDate,
            element.NumberPackageRegisterExpire,
            element.CurrentCycle,
            element.NumberStatus,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'DA-XU-LY-' + FileName);
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tên file
                </TableCell>
                <TableCell>
                  Số đã quét
                </TableCell>
                <TableCell>
                  Ngày tải lên
                </TableCell>
                <TableCell>
                  Người tải lên
                </TableCell>
                <TableCell>
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((quetdata) => (
                <TableRow
                  hover
                  key={quetdata.id}
                  selected={selectedQuetdataIds.indexOf(quetdata.id) !== -1}
                >
                  <TableCell>
                    {quetdata.FileName}
                  </TableCell>
                  <TableCell>
                    {quetdata.so_da_quet + "/" + quetdata.total_numbers} <br></br> {"Tổng gói: " + quetdata.goi_da_quet}
                  </TableCell>
                  <TableCell>
                    {quetdata.ngay_upload}
                  </TableCell>
                  <TableCell>
                    {quetdata.nguoi_upload}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => batDauQuet(quetdata.FileName)}>
                      Quét
                    </Button>/
                    <Button onClick={() => dungQuet(quetdata.FileName)}>
                      Dừng
                    </Button>/
                    <Button onClick={() => xuatSoDaQuet(quetdata.FileName)}>
                      Xuất file
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default QuetdataLookupDetail;
