import { Helmet } from 'react-helmet';
import DailyDetail
  from 'src/components/daily-detail/DailyDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import DailyListToolbar from '../components/daily-detail/DailyToolbar';

const Daily = () => (
  <>
    <Helmet>
      <title>Quản lý đại lý</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <DailyListToolbar />
        <Box sx={{ pt: 3 }}>
          <DailyDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Daily;
