import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { CustomerTypes } from 'src/utils/constants';

import io from 'socket.io-client';

import { Search as SearchIcon } from 'react-feather';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  TableRow,
  TableCell,
  Typography,
  Table,
  TableHead,
  TableBody,
  MenuItem,
  Select,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import { LookupAction } from 'src/actions/lookup.action';
import { OtptracuuAction } from 'src/actions/otptracuu.action';

import { showToast } from 'src/utils/common';

import PerfectScrollbar from 'react-perfect-scrollbar';

import numeral from 'numeral';

// const socket = io("https://sys.dvmobifone.com", { rejectUnauthorized: false })

// /** MAIN CONFIG **/
// const VAS_CLIENT_ID = "PGeyhibEDXUFliwERbD6MbOBcF7QJb1AexX2tyhLbFPkSlg6PPUKABgK0PLZtybAzMTbOsd4f4v8cXJSEyzkoD9a14vzzjVOIIRn"
// /** =========== **/

// socket.emit("vas-client", VAS_CLIENT_ID)
// socket.on("connect", () => {
//   console.log("Đã kết nối với máy chủ VAS")
// })

// socket.on("disconnect", () => {
//   console.log("Mất kết nối với máy chủ VAS")
// })

const CustomerLookupInfo = (props) => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({});
  const [filterPayload, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [cusInfo, setCusInfo] = useState({});
  const [searchPhone, setSearchPhone] = useState({phone: ""});
  const { items, total } = useSelector(state => state.otptracuu);

  useEffect(() => {
    dispatch(OtptracuuAction.list())
  }, [])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleFilter = (event) => {
    let obj = {
      ...filterPayload,
      [event.target.name]: event.target.value
    }
    console.log(obj);
    setFilter(obj);
    dispatch(OtptracuuAction.list(obj))
  };

  const deleteAccount = async (id) => {
    
    const question = "Xác nhận xóa tài khoản này?";
    if (window.confirm(question)) {
      try {
        await dispatch(OtptracuuAction.deleteAccount(filterPayload, id));
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  }

  const loginFast = async (username, password, type) => {
    console.log(username, password);

    try {
      let result = null;

      if (type == 'app_mobile_smcs') result = await dispatch(OtptracuuAction.loginAppMobileSmcs(filterPayload, username, password, type));
      else if (type == 'digishop') result = await dispatch(OtptracuuAction.loginDigishop(filterPayload, username, password, type));
      else if (type == 'mobile') result = await dispatch(OtptracuuAction.loginMobile(filterPayload, username, password, type));
      else if (type == 'tra_cuu_luu_luong') result = await dispatch(OtptracuuAction.loginTraCuuLuuLuong(filterPayload, username, password, type));
      else result = await dispatch(OtptracuuAction.login(filterPayload, username, password, type));
      
      console.log(result);

      if (result && result.result == 'success') {
        if (type == 'digishop') showToast('success', 'Đăng nhập thành công tài khoản ' + type.toUpperCase() + '!')
        else showToast('success', 'Đăng nhập thành công! Mời bạn nhập OTP ' + type.toUpperCase())
      } else {
        showToast('error', 'Đăng nhập ' + type.toUpperCase() + ' thất bại!');
      }
    } catch (e) {
      showToast('error', 'Đăng nhập ' + type.toUpperCase() + ' thất bại!');
    }
  }

  const confirmFast = async (username, type) => {
    const otp = values["fastotp_" + username + '_' + type];
    console.log(username, otp);

    try {
      let result = null;

      if (type == 'app_mobile_smcs') result = await dispatch(OtptracuuAction.confirmAppMobileSmcs(filterPayload, username, otp, type));
      else if (type == 'digishop') result = await dispatch(OtptracuuAction.confirmDigishop(filterPayload, username, otp, type));
      else if (type == 'mobile') result = await dispatch(OtptracuuAction.confirmMobile(filterPayload, username, otp, type));
      else if (type == 'tra_cuu_luu_luong') result = await dispatch(OtptracuuAction.confirmTraCuuLuuLuong(filterPayload, username, otp, type));
      else result = await dispatch(OtptracuuAction.confirm(filterPayload, username, otp, type));
      
      console.log(result);

      if (result && result.result == 'success') {
        showToast('success', 'OTP ' + type.toUpperCase() + ' xác thực thành công!')
      } else {
        showToast('error', 'OTP ' + type.toUpperCase() + ' xác thực thất bại!');
      }
    } catch (e) {
      showToast('error', 'OTP ' + type.toUpperCase() + ' xác thực thất bại!');
    }
  }

  const login = async (type) => {
    const username = values['username_' + type];
    const password = values['password_' + type];
    console.log(username, password);

    try {
      let result = null;
      
      if (type == 'app_mobile_smcs') result = await dispatch(OtptracuuAction.loginAppMobileSmcs(filterPayload, username, password, type));
      else if (type == 'digishop') result = await dispatch(OtptracuuAction.loginDigishop(filterPayload, username, password, type));
      else if (type == 'mobile') result = await dispatch(OtptracuuAction.loginMobile(filterPayload, username, password, type));
      else if (type == 'tra_cuu_luu_luong') result = await dispatch(OtptracuuAction.loginTraCuuLuuLuong(filterPayload, username, password, type));
      else result = await dispatch(OtptracuuAction.login(filterPayload, username, password, type));
      
      console.log(result);

      if (result && result.result == 'success') {
        if (type == 'digishop') showToast('success', 'Đăng nhập thành công tài khoản ' + type.toUpperCase() + '!')
        else showToast('success', 'Đăng nhập thành công! Mời bạn nhập OTP ' + type.toUpperCase())
      } else {
        showToast('error', 'Đăng nhập ' + type.toUpperCase() + ' thất bại!');
      }
    } catch (e) {
      showToast('error', 'Đăng nhập ' + type.toUpperCase() + ' thất bại!');
    }
  }

  const confirm = async (type) => {
    const username = values['username_' + type];
    const otp = values['otp_' + type];
    console.log(username, otp);

    try {
      let result = null;
      
      if (type == 'app_mobile_smcs') result = await dispatch(OtptracuuAction.confirmAppMobileSmcs(filterPayload, username, otp, type));
      else if (type == 'digishop') result = await dispatch(OtptracuuAction.confirmDigishop(filterPayload, username, otp, type));
      else if (type == 'mobile') result = await dispatch(OtptracuuAction.confirmMobile(filterPayload, username, otp, type));
      else if (type == 'tra_cuu_luu_luong') result = await dispatch(OtptracuuAction.confirmTraCuuLuuLuong(filterPayload, username, otp, type));
      else result = await dispatch(OtptracuuAction.confirm(filterPayload, username, otp, type));
      
      console.log(result);

      if (result && result.result == 'success') {
        showToast('success', 'OTP ' + type.toUpperCase() + ' xác thực thành công!')
      } else {
        showToast('error', 'OTP ' + type.toUpperCase() + ' xác thực thất bại!');
      }
    } catch (e) {
      showToast('error', 'OTP ' + type.toUpperCase() + ' xác thực thất bại!');
    }
  }

  const resetSession = async (username, type) => {

    try {
      const result = await dispatch(OtptracuuAction.resetSession(filterPayload, username, type));
      console.log(result);

      if (result && result.result == 'success') {
        showToast('success', 'Reset thành công!')
      } else {
        showToast('error', 'Reset thất bại!');
      }
    } catch (e) {
      showToast('error', 'Reset xác thực thất bại!');
    }
  }

  const setStatus = async (id) => {

    try {
      const result = await dispatch(OtptracuuAction.setStatus(filterPayload, id));
      console.log(result);

      if (result && result.result == 'success') {
        showToast('success', 'Đặt trạng thái thành công!')
      } else {
        showToast('error', 'Đặt trạng thái thất bại!');
      }
    } catch (e) {
      showToast('error', 'Đặt trạng thái xác thực thất bại!');
    }
  }

  const checkTraCuu = async (username, type) => {

    try {
      const result = await dispatch(OtptracuuAction.checkTraCuu(filterPayload, username, type));
      console.log(result);

      if (result && result.result == 'success') {
        showToast('success', 'Có thể tra cứu!')
      } else {
        showToast('error', 'Chưa thể tra cứu!');
      }
    } catch (e) {
      showToast('error', 'Chưa thể tra cứu!');
    }
  }

  return (
    <>
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tài khoản
                </TableCell>
                <TableCell>
                  <Select
                    style={{ height: 56, marginTop: 0, width: "250px", marginLeft: 0 }}
                    name="loai_tai_khoan"
                    value={filterPayload.loai_tai_khoan || -99}
                    onChange={(newValue) => handleFilter(newValue)}
                    variant="outlined"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )}
                  >
                    <MenuItem value="-99">Loại tài khoản...</MenuItem>
                    <MenuItem value="smcs">SMCS</MenuItem>
                    <MenuItem value="ccos">CCOS</MenuItem>
                    <MenuItem value="ccos_view360">CCOS VIEW360</MenuItem>
                    <MenuItem value="app_mobile_smcs">APP SMCS</MenuItem>
                    <MenuItem value="digishop">APP DIGISHOP</MenuItem>
                    <MenuItem value="mobile">APP VNPT EMPLOYEE</MenuItem>
                    <MenuItem value="tra_cuu_luu_luong">TRA CỨU LƯU LƯỢNG (APP VNPT EMPLOYEE)</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  Đăng nhập nhanh
                </TableCell>
                <TableCell>
                  Check tra cứu
                </TableCell>
                <TableCell>
                  Lượt tra cứu còn lại (Theo phút / Theo giờ)
                </TableCell>
                <TableCell>
                  Reset
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Thao tác
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {item.username}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {item.type}
                  </TableCell>
                  <TableCell style={{width: '250px'}}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => loginFast(item.username, '__FAST_LOGIN__', item.type)}
                      style={{marginTop: 10}}
                    >
                      Đăng nhập nhanh
                    </Button>

                    {item.type != 'digishop' && <TextField
                      label="OTP"
                      margin="normal"
                      name={"fastotp_" + item.username + '_' + item.type}
                      required
                      onChange={handleChange}
                      value={values["fastotp_" + item.username + '_' + item.type]}
                      variant="outlined"
                      style={{marginTop: 20, width: '100%'}}
                    />}

                    {item.type != 'digishop' && <Button
                      color="primary"
                      variant="contained"
                      onClick={() => confirmFast(item.username, item.type)}
                      style={{marginTop: 10}}
                    >
                      Xác nhận OTP
                    </Button>}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => checkTraCuu(item.username, item.type)}
                      style={{marginTop: 10}}
                    >
                      CHECK
                    </Button>
                    <div style={{width: '100%', marginTop: 6, color: item.checkTraCuu == 1 ? 'green' : 'red'}}>{item.checkTraCuu == 1 ? 'CÓ THỂ TRA CỨU' : 'CHƯA THỂ TRA CỨU'}</div>
                  </TableCell>
                  <TableCell>
                    {numeral(item.time_left_in_a_minute).format('0,0')} / {numeral(item.time_left_in_an_hour).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {(item.type == 'ccos' || item.type == 'ccos_view360') && <Button
                      color="primary"
                      variant="contained"
                      onClick={() => resetSession(item.username, item.type)}
                      style={{marginTop: 10}}
                    >
                      RESET SESSION
                    </Button>}
                  </TableCell>
                  <TableCell>
                    <Button
                      color={item.status == '1' ? 'success' : 'error'}
                      variant="contained"
                      onClick={() => setStatus(item.id)}
                      style={{marginTop: 10}}
                    >
                      {item.status == '1' ? 'ĐÃ KÍCH HOẠT' : 'KHÔNG KÍCH HOẠT'}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => deleteAccount(item.id)}
                      style={{marginTop: 10}}
                    >
                      XÓA
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>

    <form {...props}>
      <Card style={{ marginTop: 20, width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP APP VNPT EMPLOYEE"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_mobile"
            required
            onChange={handleChange}
            value={values.username_mobile}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_mobile"
            required
            onChange={handleChange}
            value={values.password_mobile}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('mobile')}
            style={{marginTop: 10}}
          >
            Đăng nhập APP VNPT EMPLOYEE
          </Button>
          <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_mobile"
            required
            onChange={handleChange}
            value={values.otp_mobile}
            variant="outlined"
            style={{marginTop: 20}}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('mobile')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP APP VNPT EMPLOYEE
          </Button>
        </CardContent>
      </Card>
    </form>
    <form {...props}>
      <Card style={{ width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP TRA CỨU LƯU LƯỢNG (APP VNPT EMPLOYEE)"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_tra_cuu_luu_luong"
            required
            onChange={handleChange}
            value={values.username_tra_cuu_luu_luong}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_tra_cuu_luu_luong"
            required
            onChange={handleChange}
            value={values.password_tra_cuu_luu_luong}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('tra_cuu_luu_luong')}
            style={{marginTop: 10}}
          >
            Đăng nhập TRA CỨU LƯU LƯỢNG (APP VNPT EMPLOYEE)
          </Button>
          <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_tra_cuu_luu_luong"
            required
            onChange={handleChange}
            value={values.otp_tra_cuu_luu_luong}
            variant="outlined"
            style={{marginTop: 20}}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('tra_cuu_luu_luong')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP TRA CỨU LƯU LƯỢNG (APP VNPT EMPLOYEE)
          </Button>
        </CardContent>
      </Card>
    </form>
    <form {...props}>
      <Card style={{ width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP APP DIGISHOP"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_digishop"
            required
            onChange={handleChange}
            value={values.username_digishop}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_digishop"
            required
            onChange={handleChange}
            value={values.password_digishop}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('digishop')}
            style={{marginTop: 10}}
          >
            Đăng nhập APP DIGISHOP
          </Button>
          {1 < 0 && <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_digishop"
            required
            onChange={handleChange}
            value={values.otp_mobile}
            variant="outlined"
            style={{marginTop: 20}}
          />}
          {1 < 0 && <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('digishop')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP APP DIGISHOP
          </Button>}
        </CardContent>
      </Card>
    </form>
    <form {...props}>
      <Card style={{ width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP CCOS"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_ccos"
            required
            onChange={handleChange}
            value={values.username_ccos}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_ccos"
            required
            onChange={handleChange}
            value={values.password_ccos}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('ccos')}
            style={{marginTop: 10}}
          >
            Đăng nhập CCOS
          </Button>
          <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_ccos"
            required
            onChange={handleChange}
            value={values.otp_ccos}
            variant="outlined"
            style={{marginTop: 20}}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('ccos')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP CCOS
          </Button>
        </CardContent>
      </Card>
    </form>
    <form {...props} style={{marginTop: 30}}>
      <Card style={{ width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP CCOS VIEW360"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_ccos_view360"
            required
            onChange={handleChange}
            value={values.username_ccos_view360}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_ccos_view360"
            required
            onChange={handleChange}
            value={values.password_ccos_view360}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('ccos_view360')}
            style={{marginTop: 10}}
          >
            Đăng nhập CCOS VIEW360
          </Button>
          <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_ccos_view360"
            required
            onChange={handleChange}
            value={values.otp_ccos_view360}
            variant="outlined"
            style={{marginTop: 20}}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('ccos_view360')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP CCOS VIEW360
          </Button>
        </CardContent>
      </Card>
    </form>
    <form {...props} style={{marginTop: 30}}>
      <Card style={{ width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP SMCS"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_smcs"
            required
            onChange={handleChange}
            value={values.username_smcs}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_smcs"
            required
            onChange={handleChange}
            value={values.password_smcs}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('smcs')}
            style={{marginTop: 10}}
          >
            Đăng nhập SMCS
          </Button>
          <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_smcs"
            required
            onChange={handleChange}
            value={values.otp_smcs}
            variant="outlined"
            style={{marginTop: 20}}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('smcs')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP SMCS
          </Button>
        </CardContent>
      </Card>
    </form>
    <form {...props} style={{marginTop: 30}}>
      <Card style={{ width: "100%"}}>
        <CardHeader
          title="ĐĂNG NHẬP APP MOBILE SMCS"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Username"
            margin="normal"
            name="username_app_mobile_smcs"
            required
            onChange={handleChange}
            value={values.username_app_mobile_smcs}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password_app_mobile_smcs"
            required
            onChange={handleChange}
            value={values.password_app_mobile_smcs}
            variant="outlined"
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => login('app_mobile_smcs')}
            style={{marginTop: 10}}
          >
            Đăng nhập APP MOBILE SMCS
          </Button>
          <TextField
            fullWidth
            label="OTP"
            margin="normal"
            name="otp_app_mobile_smcs"
            required
            onChange={handleChange}
            value={values.otp_app_mobile_smcs}
            variant="outlined"
            style={{marginTop: 20}}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => confirm('app_mobile_smcs')}
            style={{marginTop: 10}}
          >
            Xác nhận OTP APP MOBILE SMCS
          </Button>
        </CardContent>
      </Card>
    </form>
    </>
  )
};

export default CustomerLookupInfo;
