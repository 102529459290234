import React, { useEffect, useState } from 'react';
import { Search as SearchIcon, Upload as UploadIcon, Plus as PlusIcon, Phone as PhoneIcon, AlignLeft } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { BlacklistAction } from 'src/actions/blacklist.action';
import * as XLSX from 'xlsx';

import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import CreateEditDialog from './CreateEditDialog';

const BlacklistToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);

  const [values, setValues] = useState({
    message: "",
    description: "",
  });

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      description: "",
    };
    dispatch(BlacklistAction.updateFilter(payload));
  }, []);

  const fileInput = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(0).map((c) => ({
        phone_number: c[0],
        description: c[1],
      }));
      let temp1 = {}
      if (temp != null && temp.length > 0) {
        temp.forEach(function (element) {
          temp1[element.phone_number] = element
        })
      }
      temp1 = Object.values(temp1)
      dispatch(BlacklistAction.saveDataUpload(temp1));
      setPreview(true)
      e.target.value = null;
      alert("Tải file thành công: \"" + files[0].name + "\" (" + temp1.length + " bản ghi)");
      dispatch(BlacklistAction.list());
    };
    reader.readAsBinaryString(f)
  }

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(BlacklistAction.countAssign())
    setAssign(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      description: values.description,
    };
    dispatch(BlacklistAction.updateFilter(payload));
    dispatch(BlacklistAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <input
          ref={fileInput}
          type="file"
          onChange={(e) => handleImportFile(e)}
          style={{ display: 'none' }}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => fileInput.current.click()}
          startIcon={<UploadIcon size={15} />}
        >
          Tải dữ liệu
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginLeft: 6 }}
          startIcon={<PlusIcon size={15} />}
        >
          Tạo bản ghi
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <PhoneIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    name="message"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    value={values.message}
                    placeholder="Tìm SĐT"
                    variant="outlined"
                    style={{ height: 56, marginTop: 6, marginLeft: 0 }}
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <AlignLeft />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    name="description"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    value={values.description}
                    placeholder="Tìm mô tả"
                    variant="outlined"
                    style={{ height: 56, marginTop: 6, marginLeft: 0 }}
                  />
                </Grid>
              </Grid>

              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 10 }}
                startIcon={<SearchIcon size={15} />}
              >
                Tra cứu
              </Button>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
    </Box>
  )
};

export default BlacklistToolbar;
