import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { DailyAction } from 'src/actions/daily.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    MenuItem,
    Select,
    InputAdornment,
    SvgIcon,
} from '@material-ui/core';

import {
    Edit3 as Edit3Icon,
    Lock,
    RefreshCcw,
    Trash2 as Trash2Icon,
    Unlock,
    Search as SearchIcon,
  } from 'react-feather';

import { AccountAction } from 'src/actions/account.acction';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.daily)
    const { branch_no_limit_items, branch_no_limit_total, leader_no_limit_items, leader_no_limit_total, sale_no_limit_items, sale_no_limit_total } = useSelector(state => state.account);
    const [BRANCH_NAME, setBranchName] = useState("")
    const [BRANCH_CODE, setBranchCode] = useState("")
    const [BRANCH_SALES_CODE, setBranchSalesCode] = useState("")
    const [BRANCH_TEL, setBranchTel] = useState("")
    const [BRANCH_MOBILE, setBranchMobile] = useState("")
    const [username_he_thong, setUsernameHeThong] = useState("")
    const [is_branch, setIsBranch] = useState("")
    const [de_moi_goi_hom_sau, setDeMoiGoiHomSau] = useState("")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        dispatch(AccountAction.listBranchNoLimit())
        dispatch(AccountAction.listLeaderNoLimit())
        dispatch(AccountAction.listSaleNoLimit())
        if (props.type === 'EDIT') {
            if (selected) {
                setBranchName(selected.BRANCH_NAME)
                setBranchCode(selected.BRANCH_CODE)
                setBranchSalesCode(selected.BRANCH_SALES_CODE)
                setBranchTel(selected.BRANCH_TEL)
                setBranchMobile(selected.BRANCH_MOBILE)
                setUsernameHeThong(selected.username_he_thong)
                setIsBranch(selected.is_branch)
                setDeMoiGoiHomSau(selected.de_moi_goi_hom_sau)
            }
        }
    }, [selected])

    const onPressPositive = () => {
        if (BRANCH_NAME === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (props.type !== 'EDIT' && BRANCH_CODE === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (props.type !== 'EDIT' && BRANCH_SALES_CODE === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (BRANCH_TEL === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (BRANCH_MOBILE === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (is_branch === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (de_moi_goi_hom_sau === "") {
            alert("Có trường bắt buộc chưa được chọn hoặc nhập!");
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(DailyAction.update(selected.BRANCH_ID, { BRANCH_NAME, BRANCH_TEL, BRANCH_MOBILE, username_he_thong: username_he_thong != -99 ? username_he_thong : null, is_branch, de_moi_goi_hom_sau }))
        } else {
            dispatch(DailyAction.create({ BRANCH_NAME, BRANCH_CODE, BRANCH_SALES_CODE, BRANCH_TEL, BRANCH_MOBILE, username_he_thong: username_he_thong != -99 ? username_he_thong : null, is_branch, de_moi_goi_hom_sau }))
        }
        resetState();
        props.handleClose();
    }

    const resetState = () => {
        setBranchName("")
        setBranchCode("")
        setBranchSalesCode("")
        setBranchTel("")
        setBranchMobile("")
        setUsernameHeThong("-99")
        setIsBranch("-99")
        setDeMoiGoiHomSau("-99")
    }

    const handleSelectOne = (event, BRANCH_ID) => {
        const selectedIndex = selectedPermissionIds.indexOf(BRANCH_ID);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, BRANCH_ID);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Tên chi nhánh"
                            name="BRANCH_NAME"
                            onChange={(e) => setBranchName(e.target.value)}
                            required
                            value={BRANCH_NAME}
                            variant="outlined"
                        />
                    </Grid>
                    {props.type !== 'EDIT' && <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Mã chi nhánh"
                            name="BRANCH_CODE"
                            onChange={(e) => setBranchCode(e.target.value)}
                            required
                            value={BRANCH_CODE}
                            variant="outlined"
                        />
                    </Grid>}
                    {props.type !== 'EDIT' && <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Mã GD chi nhánh"
                            name="BRANCH_SALES_CODE"
                            onChange={(e) => setBranchSalesCode(e.target.value)}
                            required
                            value={BRANCH_SALES_CODE}
                            variant="outlined"
                        />
                    </Grid>}
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="SĐT bàn chi nhánh"
                            name="BRANCH_TEL"
                            onChange={(e) => setBranchTel(e.target.value)}
                            required
                            value={BRANCH_TEL}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="SĐT DĐ chi nhánh"
                            name="BRANCH_MOBILE"
                            onChange={(e) => setBranchMobile(e.target.value)}
                            required
                            value={BRANCH_MOBILE}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" style={{color: is_branch == 1 ? 'green' : (is_branch == 2 ? 'red' : 'black')}} name="is_branch" value={is_branch || -99} onChange={(e) => {setIsBranch(e.target.value)}}>
                            <MenuItem value="-99" selected>Là chi nhánh hay không?</MenuItem>
                            <MenuItem value="1" style={{color: 'green'}}>CÓ</MenuItem>
                            <MenuItem value="2" style={{color: 'red'}}>KHÔNG</MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" style={{color: de_moi_goi_hom_sau == 1 ? 'green' : (de_moi_goi_hom_sau == 2 ? 'red' : 'black')}} name="de_moi_goi_hom_sau" value={de_moi_goi_hom_sau || -99} onChange={(e) => {setDeMoiGoiHomSau(e.target.value)}}>
                            <MenuItem value="-99" selected>Là mã để mời gói hôm sau hay không?</MenuItem>
                            <MenuItem value="1" style={{color: 'green'}}>CÓ</MenuItem>
                            <MenuItem value="2" style={{color: 'red'}}>KHÔNG</MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Select
                            style={{ height: 56, marginTop: 6, width: "100%", marginLeft: 0 }}
                            name='username_he_thong'
                            value={username_he_thong || -99}
                            onChange={(e) => setUsernameHeThong(e.target.value)}
                            variant="outlined"
                            startAdornment={(
                            <InputAdornment position="start">
                                <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                                </SvgIcon>
                            </InputAdornment>
                            )}
                        >
                            <MenuItem value="-99">Chọn user...</MenuItem>
                            {branch_no_limit_items && branch_no_limit_items.map((account, idx) => (
                                <MenuItem value={account.username}>CHI NHÁNH - {account.username} - {account.fullname}</MenuItem>
                            ))}
                            {leader_no_limit_items && leader_no_limit_items.map((account, idx) => (
                                <MenuItem value={account.username}>LEADER - {account.username} - {account.fullname}</MenuItem>
                            ))}
                            {sale_no_limit_items && sale_no_limit_items.map((account, idx) => (
                                <MenuItem value={account.username}>SALEMAN - {account.username} - {account.fullname}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;