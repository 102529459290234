import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ReportstaffrevenueAction } from 'src/actions/reportstaffrevenue.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';

import numeral from 'numeral';

const ReportstaffrevenueDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongDoanhThuTamTinh, tongDoanhThuThucTe, tongHoanTien, tongGoi } = useSelector(state => state.reportstaffrevenue);
  const { role } = useSelector(state => state.profile);

  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [updateUserValue, setUpdateUserValue] = useState({});


  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    
    dispatch(ReportstaffrevenueAction.updateFilter(payload));

    dispatch(ReportstaffrevenueAction.list());
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // const handleUserChange = (event, username, track0, track1) => {
  //   if (event.key === 'Enter') {
  //     username = username.trim();
  //     if (username != '') {
  //       setUpdateUserValue({
  //         ...updateUserValue,
  //         [track0 + '_' + track1]: username,
  //       });
  //       dispatch(ReportstaffrevenueAction.updateUser(username, track0, track1));
  //     }
  //   }
  // };

  const handleUserChange = (event, username, track0, track1) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [track0 + '_' + track1]: username,
      });
      dispatch(ReportstaffrevenueAction.updateUser(username, track0, track1));
    }
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG GÓI
                </TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG DOANH THU TẠM TÍNH
                </TableCell>
                {1 < 0 && <TableCell>

                </TableCell>}
                {/* <TableCell style={{color: "green"}}>
                  TỔNG DOANH THU THỰC TẾ
                </TableCell> */}
                <TableCell style={{color: "green"}}>
                  TỔNG HOÀN TIỀN
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongGoi).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongDoanhThuTamTinh).format('0,0')}
                </TableCell>
                {1 < 0 && <TableCell style={{color: "green"}}>
                  
                </TableCell>}
                {/* <TableCell style={{color: "green"}}>
                  {numeral(tongDoanhThuThucTe).format('0,0')}
                </TableCell> */}
                <TableCell style={{color: "green"}}>
                  {numeral(tongHoanTien).format('0,0')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Username NV
                </TableCell>
                <TableCell>
                  Tên NV
                </TableCell>
                <TableCell>
                  Tổng gói
                </TableCell>
                <TableCell>
                  Doanh thu tạm tính
                </TableCell>
                {1 < 0 && <TableCell>
                  Doanh thu báo đỏ
                </TableCell>}
                {/* <TableCell>
                  Doanh thu thực tế
                </TableCell> */}
                <TableCell>
                  Tổng hoàn tiền
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((reportstaffrevenue, index) => (
                <TableRow
                  hover
                  key={reportstaffrevenue.track0 + '_' + reportstaffrevenue.track1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {reportstaffrevenue.username_nv}
                  </TableCell>
                  <TableCell>
                    {reportstaffrevenue.ten_nv}
                  </TableCell>
                  <TableCell>
                    {numeral(reportstaffrevenue.don_moi).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {numeral(reportstaffrevenue.doanh_thu_tam_tinh).format('0,0')}
                  </TableCell>
                  {1 < 0 && <TableCell>
                    {numeral(reportstaffrevenue.doanh_thu_bao_do).format('0,0')}
                  </TableCell>}
                  {/* <TableCell>
                    {numeral(reportstaffrevenue.doanh_thu_thuc_te).format('0,0')}
                  </TableCell> */}
                  <TableCell>
                    {numeral(reportstaffrevenue.tong_hoan_tien).format('0,0')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default ReportstaffrevenueDetail;
