import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ReportAction } from 'src/actions/report.acction';
import { RoleAction } from 'src/actions/role.acction';

import {
  DEFAULT_PAGE,
  DEFAULT_ROW_PER_PAGE,
  InvitationStatus,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
} from '@material-ui/core';
import { Moigoi1543Action } from 'src/actions/moigoi1543.action';

import { Search as SearchIcon, Phone as PhoneIcon, Phone } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ReportListResults1 = (props) => {

  const dispatch = useDispatch()
  const { query9332, items9332, total9332, query9084, items9084, total9084 } = useSelector(state => state.report)
  //const [items, setItems] = useState([])
  const [query, setQuery] = useState(undefined)
  //const [total, setTotal] = useState(0)
  const { list_1543_items, list_1543_total, filter1 } = useSelector(state => state.moigoi1543)
  const [limit, setLimit] = useState(filter1.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter1.page || 0);
  const { role } = useSelector(state => state.profile)

  useState(state => console.log(state))

  // useEffect(() => {
  //   if (props.type === '9332') {
  //     let payload = query || {
  //       endDate: moment(new Date()).format('DD/MM/YYYY'),
  //       status: '',
  //       startDate: moment(new Date()).format('DD/MM/YYYY'),
  //       user: '',
  //       page: DEFAULT_PAGE,
  //       count: DEFAULT_ROW_PER_PAGE
  //     }
  //     // dispatch(ReportAction.list9332(payload))
  //     dispatch(Moigoi1543Action.list(payload))
  //   }
  // }, [page])

  // useEffect(() => {
  //   if (props.type === '9332') {
  //     setTotal(total9332)
  //     setItems(items9332)
  //     setQuery(query9332)
  //   }
  // }, [query9332, items9332, total9332])

  // useEffect(() => {
  //   if (props.type === '9084') {
  //     setTotal(total9084)
  //     setItems(items9084)
  //     setQuery(query9084)
  //   }
  // }, [query9084, items9084, total9084])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const onSelected = (id) => {
    dispatch(RoleAction.findOne(id));
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handlephoneChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    const payload = {
      ...filter1,
      limit: limit,
      page: page,
    };
    dispatch(Moigoi1543Action.updateFilter1(payload));
    dispatch(Moigoi1543Action.list())
  }, [page, limit]);

  const [values, setValues] = useState({
    phone: "",
    startDate: filter1 ? dayjs(filter1.startDate, moment(new Date()).format('DD/MM/YYYY')) : '',
    endDate: filter1 ? dayjs(filter1.endDate, moment(new Date()).format('DD/MM/YYYY')) : '',
  });

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(Moigoi1543Action.updateFilter1(payload));
    dispatch(Moigoi1543Action.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  console.log(list_1543_total)

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <PhoneIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="phone"
                onChange={handlephoneChange}
                onKeyDown={handleKeyDown}
                value={values.phone}
                placeholder="Tìm SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 6, marginLeft: 0 }}
              />
            </Grid>

            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <DatePicker
                label="Thời gian bắt đầu"
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
              />
            </Grid>

            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <DatePicker
                label="Thời gian kết thúc"
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </Grid>
          </Grid>

          <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            style={{ height: 56, marginTop: 10, marginLeft: 0 }}
            startIcon={<SearchIcon size={15} />}
          >
            Tra cứu
          </Button>
        </LocalizationProvider>
      </CardContent>
    </Card>
      <Card>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    #
                  </TableCell>
                  <TableCell>
                    •Số thuê bao<br></br>•Tên gói cước<br></br>•Giá gói cước
                  </TableCell>
                  <TableCell>
                    •Mã nhân viên<br></br>•Tên nhân viên
                  </TableCell>
                  <TableCell>
                    Trạng thái mời gói
                  </TableCell>
                  {/* <TableCell>
                  Mã kết quả
                </TableCell>
                <TableCell>
                  Loại giao dịch
                </TableCell>
                <TableCell>
                  Tin nhắn trả về
                </TableCell> */}
                  <TableCell>
                    Thời gian
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_1543_items && list_1543_items.slice(page * limit, page * limit + limit).map((item, idx) => (
                  <TableRow
                    hover
                    key={item.id}
                  >
                    <TableCell >
                      {idx + 1}
                    </TableCell>
                    <TableCell>
                      •{role.priority < 3 ? item.so_thue_bao.slice(-9) : ('****' + item.so_thue_bao.slice(-5))}
                      <br></br>
                      •{item.ten_goi_cuoc}
                      <br></br>
                      •{item.gia_goi_cuoc}
                    </TableCell>
                    <TableCell>
                      {"•" + item.username_nv}
                      {<><br></br></>}
                      {"•" + item.ten_nv}
                    </TableCell>
                    <TableCell>
                      {item.trang_thai_moi_goi == 'SUCCESS' ? 'Thành công' : (item.trang_thai_moi_goi == 'FAILED' ? 'Thất bại' : '')}
                    </TableCell>
                    {/* <TableCell >
                    {item.ma_ket_qua == 'SUCCESS' ? 'Thành công' : (item.ma_ket_qua == 'FAILED' ? 'Thất bại' : '')}
                  </TableCell>
                  <TableCell >
                    {item.loai_giao_dich}
                  </TableCell>
                  <TableCell width={"20%"}>
                    {item.tin_nhan_tra_ve}
                  </TableCell> */}
                    <TableCell>
                      {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={list_1543_total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card></>
  );
};

export default ReportListResults1;
