import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { InviteAction } from 'src/actions/invite.action';
import { InviteTypes } from 'src/utils/constants';

import {
  Box,
  Button,
  TextField,
} from '@material-ui/core';

const InvitationOTP = (props) => {

  const dispatch = useDispatch()
  const { getOtp, confirmOtp } = useSelector(state => state.invite)
  const [values, setValues] = useState({
    package: '',
    phone: '',
    otp: ''
  });
  useEffect(() => {
    setValues({
      package: '',
      phone: '',
      otp: ''
    })
  }, [confirmOtp])

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  //MÃ XÁC NHẬN -> LẤY MÃ OTP
  const handleGetOTP = () => {
    dispatch(InviteAction.inviteGetOTP({
      type: InviteTypes.GET_OTP,
      phone: values.phone,
      package: values.package
    }))
  }

  //LẤY MÃ XÁC NHẬN -> ĐĂNG KÝ
  const handleConfirmOTP = () => {
    setValues({
      ...values,
      transIdGetOtp: typeof getOtp !== 'undefined' && getOtp && typeof getOtp.transIdGetOtp !== 'undefined' && getOtp.transIdGetOtp && getOtp.transIdGetOtp != '' ? getOtp.transIdGetOtp : ''
    });
    dispatch(InviteAction.inviteConfirmOTP({
      type: InviteTypes.CONFIRM_OTP,
      otp: values.otp,
      package: values.package,
      transIdGetOtp: values.transIdGetOtp,
      phone: values.phone,
    }))
  }


  return (
    <form {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          fullWidth
          label="Gói cước"
          margin="normal"
          name="package"
          onChange={handleChange}
          required
          value={values.package}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Số điện thoại"
          margin="normal"
          name="phone"
          onChange={handleChange}
          required
          value={values.phone}
          variant="outlined"
        />
        <Button
          color="primary"
          variant="contained"
          disabled={!values.phone || !values.package}
          onClick={handleGetOTP}
          style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
        >
          Lấy mã OTP
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Mã OTP của gói cước"
          margin="normal"
          name="otp"
          onChange={handleChange}
          required
          value={values.otp}
          variant="outlined"
        />
        <Button
          color="primary"
          onClick={handleConfirmOTP}
          variant="contained"
          disabled={!getOtp && !values.otp}
          style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
        >
          Đăng ký
        </Button>
      </Box>
    </form>
  );
};

export default InvitationOTP;
