import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { showToast } from 'src/utils/common';
import { OtptracuuAction } from 'src/actions/otptracuu.action';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { isGrantPermission } from 'src/utils/utils';

const CustomerLookup1543 = (props) => {
  const dispatch = useDispatch()
  const { pkg } = useSelector(state => state.lookup)
  const [cusInfoDB, setCusInfoDB] = useState({});
  const [cusInfo, setCusInfo] = useState({});
  const [clickTraCuu, setClickTraCuu] = useState(false);
  const [values, setValues] = useState({
    phone: '',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
  });
  const { info, permissions, role } = useSelector(state => state.profile)

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)
    var from_date = values.startDate.format('DD/MM/YYYY');
    var to_date = values.endDate.format('DD/MM/YYYY');
    console.log(phone, from_date, to_date, values.startDate, values.endDate)
    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuu1543DB({phone, from_date, to_date}));
      console.log(result);

      if (result && result.status == 'success') {
        console.log(result.data.values);
        setCusInfo({});
        setCusInfoDB(result.data.values);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (error) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }
  
  const onLookup1 = async () => {
    //dispatch(LookupAction.lookupPackage(values.phone))

    var phone = (values.phone).substring((values.phone).length - 9)
    var from_date = values.startDate.format('DD/MM/YYYY');
    var to_date = values.endDate.format('DD/MM/YYYY');
    console.log(phone, from_date, to_date, values.startDate, values.endDate)
    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuu1543({phone, from_date, to_date}));
      console.log(result);

      if (result && result.result == 'success') {
        console.log(result.outputData);
        setCusInfoDB({});
        setCusInfo(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(LookupAction.clearLookup())
    }
  }, [])

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  return (
    <form {...props}>
      {isGrantPermission(permissions, ['tra_cuu_5_1', 'tra_cuu_5_2']) && <Card>
        <CardHeader
          title="#5 TRA CỨU LỊCH SỬ 1543"
        />
        <Divider />
        <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <TextField
                    fullWidth
                    label="Số điện thoại"
                    margin="normal"
                    onChange={handleChange}
                    required
                    name='phone'
                    value={values.phone}
                    variant="outlined"
                    style={{ width: '250px', marginRight: 6, marginTop: 0 }}
                />
                <DatePicker
                    label="Thời gian bắt đầu"
                    style={{ width: '100%', marginRight: 24 }}
                    format='DD/MM/YYYY'
                    value={values.startDate}
                    onChange={(newValue) => handleStartDateChange(newValue)}
                />
                <DatePicker
                    label="Thời gian kết thúc"
                    style={{ width: '100%', marginLeft: 24 }}
                    value={values.endDate}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => handleEndDateChange(newValue)}
                />
            </LocalizationProvider>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          {isGrantPermission(permissions, ['tra_cuu_5_1']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            disabled={clickTraCuu}
          >
            Tra cứu 1
          </Button>}
          {isGrantPermission(permissions, ['tra_cuu_5_2']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup1}
            disabled={clickTraCuu}
            style={{marginLeft: '15px'}}
          >
            Tra cứu 2
          </Button>}
        </Box>

        {cusInfoDB && cusInfoDB.length > 0 && <Table>
          <TableHead>
            <TableRow>
              <TableCell width="40%">
                Lịch sử tin nhắn thuê bao {values.phone}
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              hover
            >
              <TableCell style={{fontWeight: 'bold'}}>
                Thời gian
              </TableCell>
              <TableCell style={{fontWeight: 'bold'}}>
                Nội dung
              </TableCell>
              <TableCell style={{fontWeight: 'bold'}}>
                Trạng thái
              </TableCell>
            </TableRow>
            {cusInfoDB.map((record) => (
              <><TableRow
                hover
              >
                <TableCell>
                  {moment(record.datetime).format("DD-MM-YYYY HH:mm:ss")}
                </TableCell>
                <TableCell>
                  {record.str_mes}
                </TableCell>
                <TableCell>
                  {record.ket_qua == 'SUCCESS' ? 'Thành công' : 'Thất bại'}
                </TableCell>
              </TableRow></>
            ))}
          </TableBody>
        </Table>}
        <div style={{ height: '500px', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: cusInfo ? cusInfo.report : '' }}></div>
      </Card>}
    </form>
  );
};

export default CustomerLookup1543;
