import { RoleService } from 'src/services/role.service';

import { AppAction } from './app.action';

export const ROLE_LIST_SUCCESS = 'ROLE:FIND_ALL_SUCCESS'
export const ROLE_LIST_FAILD = 'ROLE:FIND_ALL_FAILD'

export const ROLE_ONE_SUCCESS = 'ROLE:FIND_ONE_SUCCESS'
export const ROLE_ONE_FAILD = 'ROLE:FIND_ONE_FAILD'
export const ROLE_CLEAR_SELECTED = 'ROLE:CLEAR_SELECTED'


export const ROLE_CREATE_SUCCESS = 'ROLE:CREATE_SUCCESS'
export const ROLE_CREATE_FAILD = 'ROLE:CREATE_FAILD'

export const ROLE_DEL_SUCCESS = 'ROLE:DEL_SUCCESS'
export const ROLE_DEL_FAILD = 'ROLE:DEL_FAILD'

export const RoleAction = {
  list,
  findOne,
  create,
  update,
  clearSelect,
  del
}

function list(page = 0, count = 10,) {
  return (dispatch, getState) => {
    const { role } = getState()
    dispatch(AppAction.appLoading(true));
    RoleService.findAll(page, count).then((rs) => {
      if (page === 0) {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        dispatch(success({
          items: [...role.items, ...rs.data.values],
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ROLE_LIST_SUCCESS, data } }
  function failure(error) { return { type: ROLE_LIST_FAILD, error } }
}

function clearSelect() {
  return { type: ROLE_CLEAR_SELECTED }
}

function findOne(id) {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    RoleService.find(id).then((rs) => {
      dispatch(success(rs.data));

    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ROLE_ONE_SUCCESS, data } }
  function failure(error) { return { type: ROLE_ONE_FAILD, error } }
}


function update(id, payload) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    RoleService.update(id, payload).then((rs) => {
      dispatch(RoleAction.list())
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function failure(error) { return { type: ROLE_CREATE_FAILD, error } }
}

function create(payload) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    RoleService.create(payload).then((rs) => {
      dispatch(success(rs));
      dispatch(RoleAction.list())
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ROLE_CREATE_SUCCESS, data } }
  function failure(error) { return { type: ROLE_CREATE_FAILD, error } }
}

function del(id) {
  return dispatch => {
    dispatch(AppAction.appLoading(true));
    RoleService.delete(id).then((rs) => {
      dispatch(success(rs));
      dispatch(RoleAction.list())
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: ROLE_DEL_SUCCESS, data } }
  function failure(error) { return { type: ROLE_DEL_FAILD, error } }
}