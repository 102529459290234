import React, { useEffect, useState } from 'react';

import { Search as SearchIcon, FileText, MessageSquare, Tag as TagIcon } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { BankAction } from 'src/actions/bank.action';

import moment from 'moment';

import { AppAction } from 'src/actions/app.action';

import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';

import numeral from 'numeral';

const BankListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.bank)
  const { info, permissions, role } = useSelector(state => state.profile)

  const [values, setValues] = useState({
    message: "",
    is_read: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {
    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      is_read: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(BankAction.updateFilter(payload));
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      is_read: values.is_read,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(BankAction.updateFilter(payload));
    dispatch(BankAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        message: values.message,
        is_read: values.is_read,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(BankAction.updateFilter(payload));
      const response = await dispatch(BankAction.list());
      console.log(response)

      const data = [
        [
          '#',
          'Kênh',
          'Số tiền',
          'Thời gian',
          'Nội dung',
          'Trạng thái tự động xử lý',
          'Số điện thoại',
          'Gói cước',
          'Người cập nhật',
          'Trạng thái duyệt cập nhật',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let yeuCau = "";
          let tiLeChietKhau = "";
          let trangThaiTruyThuHoanTheoGoi = "";
          let trangThaiTruyThuHoanThem = "";

          if (element.request_type == 1) yeuCau = "Hoàn trước";
          else if (element.request_type == 2) yeuCau = "Hoàn sau";
          else if (element.request_type == 3) yeuCau = "Không hoàn";
          else if (element.request_type == 4) yeuCau = "Hoàn thêm";
          else if (element.request_type == 5) yeuCau = "Nạp tiền";

          if (element.request_method == 1) yeuCau += " - Gia hạn";
          else if (element.request_method == 2) yeuCau += " - Nâng cấp T1";
          else if (element.request_method == 3) yeuCau += " - Nâng cấp T2";
          else if (element.request_method == 4) yeuCau += " - Nâng cấp T3";

          if (element.trang_thai_truy_thu_hoan_theo_goi == 'binh_thuong') trangThaiTruyThuHoanTheoGoi = "Bình thường";
          else if (element.trang_thai_truy_thu_hoan_theo_goi == 'da_truy_thu') trangThaiTruyThuHoanTheoGoi = "Đã truy thu";
          else if (element.trang_thai_truy_thu_hoan_theo_goi == 'truy_thu') trangThaiTruyThuHoanTheoGoi = "Truy thu";

          if (element.trang_thai_truy_thu_hoan_them == 'binh_thuong') trangThaiTruyThuHoanThem = "Bình thường";
          else if (element.trang_thai_truy_thu_hoan_them == 'da_truy_thu') trangThaiTruyThuHoanThem = "Đã truy thu";
          else if (element.trang_thai_truy_thu_hoan_them == 'truy_thu') trangThaiTruyThuHoanThem = "Truy thu";

          let tiLe = '';
          let sauChietKhau = '';

          if (element.request_type == 5) {
            sauChietKhau = element.transfer_final_amount;
            tiLe = element.ti_le_chiet_khau != 0 ? element.ti_le_chiet_khau : (element.refund_price != 0 ? parseInt((element.refund_price - element.transfer_final_amount) / element.refund_price * 100) : 0)
          } else if (role.priority < 3) {
            sauChietKhau = element.tien_hoan_sau_chiet_khau;
            tiLe = element.ti_le_chiet_khau_hoan_tien != 0 ? element.ti_le_chiet_khau_hoan_tien : 0;
          }

          data.push([
            count,
            element.sender,
            numeral(element.money).format('0,0'),
            moment(element.datetime).format('DD-MM-YYYY HH:mm:ss'),
            element.str_mes,
            element.is_read == 1 ? "Đã xử lý" : "Chưa xử lý",
            element.so_dien_thoai,
            element.goi_cuoc,
            element.nguoi_cap_nhat,
            element.trang_thai_duyet_thong_tin == 1 ? "Đã duyệt" : (element.trang_thai_duyet_thong_tin == 2 ? "Đã từ chối" : ''),
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'YCHT.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
    }
  }

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <DatePicker
                    label="Thời gian bắt đầu"
                    style={{ width: '100%', marginRight: 24 }}
                    format='DD/MM/YYYY'
                    value={values.startDate}
                    onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <DatePicker
                    label="Thời gian kết thúc"
                    style={{ width: '100%', marginLeft: 24 }}
                    value={values.endDate}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => handleEndDateChange(newValue)}
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <MessageSquare />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    name="message"
                    onChange={handleMessageChange}
                    onKeyDown={handleKeyDown}
                    value={values.message}
                    placeholder="Tìm tin nhắn"
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <Select
                    style={{ height: 56, width: "200px", marginLeft: 0, color: values.is_read == 1 ? 'green' : (values.is_read == 2 ? 'red' : 'black') }}
                    name="is_read"
                    value={values.is_read || -99}
                    onChange={(newValue) => handleMessageChange(newValue)}
                    variant="outlined"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <TagIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )}
                  >
                    <MenuItem value="-99">Trạng thái xử lý...</MenuItem>
                    <MenuItem value="1" style={{ color: 'green' }}>Đã xử lý</MenuItem>
                    <MenuItem value="2" style={{ color: 'red' }}>Chưa xử lý</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <div style={{ marginTop: 10 }}>
                {role.priority != 5 && <Button
                  color="primary"
                  variant="contained"
                  onClick={xuatFile}
                  style={{ height: 56, marginLeft: 0 }}
                  startIcon={<FileText size={15} />}
                >
                  Xuất file
                </Button>}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginLeft: 5 }}
                  startIcon={<SearchIcon size={15} />}
                >
                  Tra cứu
                </Button>
              </div>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default BankListToolbar;
