import { InviteService } from 'src/services/invite.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';
import { ReportAction } from './report.acction';

import moment from 'moment';

import MainSocket from 'src/components/MainSocket.js'

export const INVITE_GET_OTP_SUCCESS = 'INVITE:GET_OTP_SUCCESS'
export const INVITE_GET_OTP_FAILD = 'INVITE:GET_OTP_FAILD'
export const INVITE_CONFIRM_OTP_SUCCESS = 'INVITE:CONFIRM_OTP_SUCCESS'
export const INVITE_CONFIRM_OTP_FAILD = 'INVITE:CONFIRM_OTP_FAILD'

export const INVITE_GET_OTPnew_SUCCESS = 'INVITE:GET_OTPnew_SUCCESS'
export const INVITE_GET_OTPnew_FAILD = 'INVITE:GET_OTPnew_FAILD'
export const INVITE_CONFIRM_OTPnew_SUCCESS = 'INVITE:CONFIRM_OTPnew_SUCCESS'
export const INVITE_CONFIRM_OTPnew_FAILD = 'INVITE:CONFIRM_OTPnew_FAILD'

export const INVITE_RENEW_SUCCESS = 'INVITE:RENEW_SUCCESS'
export const INVITE_RENEW_FAILD = 'INVITE:RENEW_FAILD'

export const InviteAction = {
    inviteGetOTP,
    inviteConfirmOTP,
    inviteGetOTPnew,
    inviteConfirmOTPnew,
    inviteRenew,
    inviteImmediate
}

function inviteGetOTP(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        InviteService.invite(payload).then((rs) => {
            dispatch(success(rs.data))
            showToast(NOTI_TYPES.SUCCESS, "Đã gửi mã OTP thành công")
        }).catch((error) => {
            dispatch(failure(error));
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_GET_OTP_SUCCESS, data } }
    function failure(error) { return { type: INVITE_GET_OTP_FAILD, data: error } }
}

function inviteConfirmOTP(payload) {
    return (dispatch, getState) => {
        const { socket, socket_client_id, domain, username_nv } = MainSocket(getState());
        dispatch(AppAction.appLoading(true));
        InviteService.invite(payload).then((rs) => {
            dispatch(success(rs.data))
            dispatch(ReportAction.list9084({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            showToast(NOTI_TYPES.SUCCESS, "Xác nhận mã OTP thành công")
            socket.emit("showThongBao", {domain: domain, content: username_nv.substring(0, 5) + "***** đã mời thành công gói " + payload.package.toUpperCase() + " cho *****" + payload.phone.slice(-3)})
        }).catch((error) => {
            dispatch(failure(error.error));
            dispatch(ReportAction.list9084({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_CONFIRM_OTP_SUCCESS, data } }
    function failure(error) { return { type: INVITE_CONFIRM_OTP_FAILD, data: error } }
}

function inviteGetOTPnew(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        InviteService.invite(payload).then((rs) => {
            dispatch(success(rs.data))
            showToast(NOTI_TYPES.SUCCESS, "Đã gửi yêu cầu mời gói thành công")
        }).catch((error) => {
            dispatch(failure(error));
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_GET_OTPnew_SUCCESS, data } }
    function failure(error) { return { type: INVITE_GET_OTPnew_FAILD, data: error } }
}

function inviteConfirmOTPnew(payload) {
    return (dispatch, getState) => {
        const { socket, socket_client_id, domain, username_nv } = MainSocket(getState());
        dispatch(AppAction.appLoading(true));
        InviteService.invite(payload).then((rs) => {
            dispatch(success(rs.data))
            dispatch(ReportAction.list9084new({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            showToast(NOTI_TYPES.SUCCESS, "Xác nhận mã OTP thành công")
            socket.emit("showThongBao", {domain: domain, content: username_nv.substring(0, 5) + "***** đã mời thành công gói " + payload.package.toUpperCase() + " cho *****" + payload.phone.slice(-3)})
        }).catch((error) => {
            dispatch(failure(error.error));
            dispatch(ReportAction.list9084new({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_CONFIRM_OTPnew_SUCCESS, data } }
    function failure(error) { return { type: INVITE_CONFIRM_OTPnew_FAILD, data: error } }
}

function inviteRenew(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        InviteService.invite(payload).then((rs) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            if (rs.status === 'success') {
                showToast(NOTI_TYPES.SUCCESS, rs.data)
            } else {
                showToast(NOTI_TYPES.ERROR, rs.error.msg)
            }
        }).catch((error) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            } else {
                showToast(NOTI_TYPES.ERROR, "Đăng ký gói cước không thành công")
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function inviteImmediate(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        InviteService.invite(payload).then((rs) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            if (rs.status === 'success') {
                showToast(NOTI_TYPES.SUCCESS, rs.data)
            } else {
                showToast(NOTI_TYPES.ERROR, rs.error.msg)
            }
        }).catch((error) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: 0,
                count: 50
            }))
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            } else {
                showToast(NOTI_TYPES.ERROR, "Đăng ký gói cước không thành công")
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}