import { TinhluongService } from 'src/services/tinhluong.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';
import {
    DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import { AppAction } from './app.action';

export const TINHLUONG_UPDATE_FILTER = 'TINHLUONG:TINHLUONG_UPDATE_FILTER'

export const TINHLUONG_NEW_CARE_SUCCESS = 'TINHLUONG:NEW_CARE_SUCCESS'
export const TINHLUONG_LIST_SUCCESS = 'TINHLUONG:FIND_ALL_SUCCESS'
export const TINHLUONG_LIST_FAILD = 'TINHLUONG:FIND_ALL_FAILD'
export const TINHLUONG_COUNT_ASSIGN_SUCCESS = 'TINHLUONG:COUNT_ASSIGN_SUCCESS'
export const TINHLUONG_COUNT_ASSIGN_FAILD = 'TINHLUONG:COUNT_ASSIGN_FAILD'
export const TINHLUONG_SELECTED_SUCCESS = 'TINHLUONG:SELECTED_SUCCESS'
export const TINHLUONG_SELECTED_FAILD = 'TINHLUONG:SELECTED_FAILD'

export const TINHLUONG_CREATE_SUCCESS = 'TINHLUONG:CREATE_SUCCESS'
export const TINHLUONG_CREATE_FAILD = 'TINHLUONG:CREATE_FAILD'

export const TINHLUONG_IMPORT_SUCCESS = 'TINHLUONG:IMPORT_SUCCESS'
export const TINHLUONG_SAVE_SUCCESS = 'TINHLUONG:SAVE_SUCCESS'
export const TINHLUONG_SAVE_FAILD = 'TINHLUONG:SAVE_SUCCESS'

export const TINHLUONG_DEL_SUCCESS = 'TINHLUONG:DEL_SUCCESS'
export const TINHLUONG_DEL_FAILD = 'TINHLUONG:DEL_FAILD'


export const TINHLUONG_HISTORY_LIST_SUCCESS = 'TINHLUONG:HISTORY_FIND_ALL_SUCCESS'
export const TINHLUONG_HISTORY_LIST_FAILD = 'TINHLUONG:HISTORY_FIND_ALL_FAILD'

export const TINHLUONG_LIST_EXPORT_SUCCESS = 'TINHLUONG:FIND_ALL_EXPORT_SUCCESS'
export const TINHLUONG_LIST_EXPORT_FAILD = 'TINHLUONG:FIND_ALL_EXPORT_FAILD'

export const TinhluongAction = {
    list,
    listExport,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    updateUser,
    selectedTinhLuong,
    updateTinhLuong,
    saveFileChamCong,
    saveFileDuLieuNhapTay,
    getConditions,
    saveConditions
}

function saveFileChamCong(payload) {
    return (dispatch, getState) => {
        const { tinhluong } = getState()
        dispatch(AppAction.appLoading(true));
        TinhluongService.saveFileChamCong(payload).then((rs) => {
            dispatch(success({
                items: {},
                total: 0,
                tongDoanhThuTamTinh: 0,
                tongDoanhThuThucTe: 0
            }));
            dispatch(TinhluongAction.list())
        }).catch((error) => {
            if (error && typeof error.code !== 'undefined' && error.code && typeof error.msg !== 'undefined' && error.msg) {
                showToast(NOTI_TYPES.ERROR, error.msg);
                dispatch(AppAction.appLoading(false));
            }
        }).finally(() => {
            //dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_LIST_SUCCESS, data } }
}

function saveFileDuLieuNhapTay(payload) {
    return (dispatch, getState) => {
        const { tinhluong } = getState()
        dispatch(AppAction.appLoading(true));
        TinhluongService.saveFileDuLieuNhapTay(payload).then((rs) => {
            dispatch(success({
                items: {},
                total: 0,
                tongDoanhThuTamTinh: 0,
                tongDoanhThuThucTe: 0
            }));
            dispatch(TinhluongAction.list())
        }).catch((error) => {
            if (error && typeof error.code !== 'undefined' && error.code && typeof error.msg !== 'undefined' && error.msg) {
                showToast(NOTI_TYPES.ERROR, error.msg);
                dispatch(AppAction.appLoading(false));
            }
        }).finally(() => {
            //dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_LIST_SUCCESS, data } }
}

function updateTinhLuong(payload) {
    return (dispatch, getState) => {
        const { tinhluong } = getState()
        dispatch(AppAction.appLoading(true));
        TinhluongService.updateTinhLuong(payload).then((rs) => {
            if (rs && typeof rs.code !== 'undefined' && rs.code && typeof rs.msg !== 'undefined' && rs.msg) {
                showToast(NOTI_TYPES.ERROR, rs.msg);
                dispatch(AppAction.appLoading(false));
            } else {
                dispatch(success(rs));
                console.log(tinhluong.items);
                if (tinhluong.items.length > 0) {
                    for (let i = 0; i < tinhluong.items.length; i++) {
                        if (tinhluong.items[i].username == payload.username && tinhluong.items[i].thangTinhLuong == payload.thangTinhLuong) {
                            if (rs && typeof rs.data !== 'undefined' && rs.data && typeof rs.data.data !== 'undefined' && rs.data.data && typeof rs.data.data.values !== 'undefined' && rs.data.data.values && typeof rs.data.data.values[0] !== 'undefined' && rs.data.data.values[0] && rs.data.data.values[0].username == payload.username && rs.data.data.values[0].thangTinhLuong == payload.thangTinhLuong) {
                                tinhluong.items[i] = rs.data.data.values[0];
                            } else {
                                tinhluong.items[i].fullname = 'Đang tính toán...'
                                for (const key in tinhluong.items[i]) {
                                    if (payload.hasOwnProperty(key)) {
                                        tinhluong.items[i][key] = payload[key];
                                    } else if (key != 'fullname') {
                                        tinhluong.items[i][key] = '0';
                                    }
                                }
                            }

                            dispatch(success({
                                items: tinhluong.items,
                                total: tinhluong.total,
                                tongDoanhThuTamTinh: tinhluong.tongDoanhThuTamTinh,
                                tongDoanhThuThucTe: tinhluong.tongDoanhThuThucTe
                            }));

                            showToast(NOTI_TYPES.SUCCESS, `Đã cập nhật NV ${payload.username} trên bảng lương tháng ${payload.thangTinhLuong}`);

                            break;
                        }
                    }
                }
                //dispatch(TinhluongAction.list())
            }
        }).catch((error) => {
            console.log(error)
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_LIST_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_CREATE_FAILD, error } }
}

function selectedTinhLuong(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.findTinhLuong(payload)
            .then(response => {
                dispatch(success(response));
            })
            .catch(error => {
                dispatch(failure());
                if (error) {
                    showToast(NOTI_TYPES.ERROR, error.msg)
                } else {
                    showToast(NOTI_TYPES.ERROR, "Chỉnh sửa không thành công")
                }
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
    };
    function success(data) { return { type: TINHLUONG_SELECTED_SUCCESS, data } }
    function failure() { return { type: TINHLUONG_SELECTED_FAILD } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { tinhluong } = getState()
        dispatch(AppAction.appLoading(true));
        TinhluongService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...tinhluong.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_LIST_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { tinhluong } = getState();
        dispatch(AppAction.appLoading(true));

        const { filter } = tinhluong;

        TinhluongService.findAll(filter.page, filter.limit, filter).then((rs) => {
            if (!!filter && filter.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tongDoanhThuTamTinh: rs.data.tongDoanhThuTamTinh,
                    tongDoanhThuThucTe: rs.data.tongDoanhThuThucTe
                }));
            } else {
                dispatch(success({
                    items: [...tinhluong.items, ...rs.data.values],
                    total: rs.data.count,
                    tongDoanhThuTamTinh: rs.data.tongDoanhThuTamTinh,
                    tongDoanhThuThucTe: rs.data.tongDoanhThuThucTe
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_LIST_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_LIST_FAILD, error } }
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { tinhluong } = getState();

            const { filter } = tinhluong;

            // Perform your asynchronous operation (e.g., API call)
            TinhluongService.listExport(filter)
                .then(response => {
                    // Handle the successful response
                    dispatch(success(response));
                    resolve(response); // Resolve the promise with the response
                })
                .catch(error => {
                    // Handle the error
                    dispatch(failure(error));
                    reject(error); // Reject the promise with the error
                });
        });
    }
    function success(data) { return { type: TINHLUONG_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_LIST_EXPORT_FAILD, error } }
}

function updateFilter(payload) {
    return { type: TINHLUONG_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: TINHLUONG_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            TinhluongService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(TinhluongAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: TINHLUONG_SAVE_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_SAVE_FAILD, error } }
}

function update(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(TinhluongAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_CREATE_FAILD, error } }
}

function updateUser(username, track0, track1) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.updateUser(username, track0, track1).then((rs) => {

        }).catch((error) => {

        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(TinhluongAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(TinhluongAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_DEL_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(TinhluongAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { tinhluong } = getState()
        dispatch(AppAction.appLoading(true));
        TinhluongService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...tinhluong.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: TINHLUONG_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: TINHLUONG_HISTORY_LIST_FAILD, error } }
}

function getConditions(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            TinhluongService.getConditions(payload)
                .then(response => {
                    // Handle the successful response
                    //dispatch(success(response));
                    //dispatch(BaodoAction.list());
                    resolve(response); // Resolve the promise with the response
                })
                .catch(error => {
                    // Handle the error
                   //dispatch(failure(error));
                    reject(error); // Reject the promise with the error
                });
        });
    };
}

function saveConditions(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        TinhluongService.saveConditions(payload).then((rs) => {
            showToast('success', "Lưu điều kiện thành công")
            //dispatch(TinhluongAction.list())
        }).catch((error) => {
            showToast('error', "Lưu điều kiện thất bại")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}