import { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { CustomerTypes } from 'src/utils/constants';

import io from 'socket.io-client';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  TableRow,
  TableCell,
  Typography,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { LookupAction } from 'src/actions/lookup.action';
import { OtptracuuAction } from 'src/actions/otptracuu.action';

import { showToast } from 'src/utils/common';

import numeral from 'numeral';

import { isGrantPermission } from 'src/utils/utils';

// const socket = io("https://sys.dvmobifone.com", { rejectUnauthorized: false })

// /** MAIN CONFIG **/
// const VAS_CLIENT_ID = "PGeyhibEDXUFliwERbD6MbOBcF7QJb1AexX2tyhLbFPkSlg6PPUKABgK0PLZtybAzMTbOsd4f4v8cXJSEyzkoD9a14vzzjVOIIRn"
// /** =========== **/

// socket.emit("vas-client", VAS_CLIENT_ID)
// socket.on("connect", () => {
//   console.log("Đã kết nối với máy chủ VAS")
// })

// socket.on("disconnect", () => {
//   console.log("Mất kết nối với máy chủ VAS")
// })

const CustomerLookupInfo = (props) => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({});
  const [data, setData] = useState([]);
  const [cusInfo, setCusInfo] = useState({});
  const [cusInfo1, setCusInfo1] = useState({});
  const [cusInfo2, setCusInfo2] = useState({});
  const [searchPhone, setSearchPhone] = useState({phone: ""});
  const [clickTraCuu, setClickTraCuu] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    // setSearchPhone({phone: phone})
    // socket.emit("traCuuThongTinChiTietThueBaoVina12qGR4fxFjBJEFY4QtMbG2wxdk89lYHKYQNv6l8tAbkG66nB42dcSXkO6wAnVr0HV2p6tAhtrbSKjJc41i2MTD5IqrhzYifkiHwv", {
    //   phone: phone,
    // })

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuThongTinChiTietThueBao(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo1({});
        setCusInfo2({});
        setCusInfo(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  const onLookup1 = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    // setSearchPhone({phone: phone})
    // socket.emit("traCuuThongTinChiTietThueBaoVina12qGR4fxFjBJEFY4QtMbG2wxdk89lYHKYQNv6l8tAbkG66nB42dcSXkO6wAnVr0HV2p6tAhtrbSKjJc41i2MTD5IqrhzYifkiHwv", {
    //   phone: phone,
    // })

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuThongTinChiTietThueBao1(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo1({});
        setCusInfo2({});
        setCusInfo(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  const onLookup2 = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuThongTinChiTietThueBao2(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo({});
        setCusInfo2({});
        setCusInfo1(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  const onLookup3 = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuThongTinChiTietThueBao3(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo({});
        setCusInfo2({});
        setCusInfo1(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  const traCuuTienNapTrongNgay = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuTienNapTrongNgay(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo({});
        setCusInfo1({});
        setCusInfo2(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  // socket.on("traKetQuaTraCuuThongTinChiTietThueBaoVina0M93yLCUz8T8oGPy8vuBKbxlq4uDRRNzgNqxK8imkTCd2e5Eo4ugTBjCdWiUbCmnXs9TNfgN0rg6DTf183sjRjhhBslRnp4cNZRr",  async (data) => {
  //   if (data != null && typeof data.data !== 'undefined' && data.data != null && typeof data.data.GetInfo !== 'undefined' && data.data.GetInfo != null && typeof data.data.GetInfo.MSISDN !== 'undefined' && data.data.GetInfo.MSISDN != null) {
  //     const result = await data.data.GetInfo.MSISDN;
  //     const searching = await searchPhone.phone;
  //     if (result.toString().slice(-9) == searching.toString().slice(-9)) {
  //       //console.log(data.data);
  //       setCusInfo(data.data);
  //     }
  //   }
  //   setSearchPhone({phone: ''})
  // })

  return (
    <form {...props}>
      {isGrantPermission(permissions, ['tra_cuu_1_tkc_1', 'tra_cuu_1_tkc_2', 'tra_cuu_1_chi_tiet_1', 'tra_cuu_1_chi_tiet_2', 'tra_cuu_1_tien_nap_trong_ngay']) && <Card>
        <CardHeader
          title="#1 TRA CỨU THÔNG TIN CHI TIẾT THUÊ BAO (GH)"
          // subheader="Giờ tra cứu 7:00 - 22:00 | Số lần tra cứu: 4 lần mỗi giờ"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            name="phone"
            required
            onChange={handleChange}
            value={values.phone}
            variant="outlined"
          />

        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          {isGrantPermission(permissions, ['tra_cuu_1_tkc_1']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup3}
            disabled={clickTraCuu}
          >
            Tra cứu TKC 1
          </Button>}
          {isGrantPermission(permissions, ['tra_cuu_1_tkc_2']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup2}
            disabled={clickTraCuu}
            style={{marginLeft: '15px'}}
          >
            Tra cứu TKC 2
          </Button>}
          {isGrantPermission(permissions, ['tra_cuu_1_chi_tiet_1']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            disabled={clickTraCuu}
            style={{marginLeft: '15px'}}
          >
            Tra cứu chi tiết 1
          </Button>}
          {isGrantPermission(permissions, ['tra_cuu_1_chi_tiet_2']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup1}
            disabled={clickTraCuu}
            style={{marginLeft: '15px'}}
          >
            Tra cứu chi tiết 2
          </Button>}
          {isGrantPermission(permissions, ['tra_cuu_1_tien_nap_trong_ngay']) && <Button
            color="primary"
            variant="contained"
            onClick={traCuuTienNapTrongNgay}
            disabled={clickTraCuu}
            style={{marginLeft: '15px'}}
          >
            Tra cứu tiền nạp trong ngày
          </Button>}
        </Box>
        <Divider />
        {cusInfo && typeof cusInfo.so_tb !== 'undefined' && cusInfo.so_tb && <><Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>

              <TableRow>
                <TableCell width="40%">
                  KẾT QUẢ
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Số thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.so_tb}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Loại SIM
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.sim_type}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Tài khoản chính
                  </Typography>
                </TableCell>
                <TableCell>
                  {numeral(cusInfo.tkc).format('0,0')}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Loại thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo.loai_tb}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </Box>
        {cusInfo.goi_cuoc && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  DANH SÁCH GÓI CƯỚC
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell colSpan={5}>
                    Các gói cước hiện tại của thuê bao {values.phone}
                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell style={{fontWeight: 'bold'}}>
                  Gói cước hiện tại
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Ngày đăng ký
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Ngày hết hạn
                </TableCell>
              </TableRow>

              {cusInfo && typeof cusInfo.goi_cuoc !== 'undefined' && cusInfo.goi_cuoc && (cusInfo.goi_cuoc).map((pkg) => (
                <><TableRow
                  hover
                >
                  <TableCell>
                    {pkg.goi_cuoc_hien_tai}
                  </TableCell>
                  <TableCell>
                    {pkg.ngay_dang_ky}
                  </TableCell>
                  <TableCell>
                    {pkg.ngay_het_han}
                  </TableCell>
                </TableRow></>
              ))}
            </TableBody>
          </Table>
        </Box>}</>}
        
        {cusInfo1 && typeof cusInfo1.so_tb !== 'undefined' && cusInfo1.so_tb && <><Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>

              <TableRow>
                <TableCell width="40%">
                  KẾT QUẢ
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Số thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo1.so_tb}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Tài khoản chính
                  </Typography>
                </TableCell>
                <TableCell>
                  {numeral(cusInfo1.tkc).format('0,0')}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </Box></>}

        {cusInfo2 && typeof cusInfo2.so_tb !== 'undefined' && cusInfo2.so_tb && <><Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>

              <TableRow>
                <TableCell width="40%">
                  KẾT QUẢ
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Số thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo2.so_tb}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Tiền đã nạp
                  </Typography>
                </TableCell>
                <TableCell>
                  {numeral(cusInfo2.tien_da_nap).format('0,0')}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </Box></>}
      </Card>}
    </form>
  )
};

export default CustomerLookupInfo;
