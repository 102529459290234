import {
  MOIGOI1543_LAY_GOI_CUOC_SUCCESS,
  MOIGOI1543_LIST_SUCCESS,
  UPDATE_FILTER1,
} from 'src/actions/moigoi1543.action';
import { LOOKUP_CLEANER } from 'src/actions/lookup.action';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import dayjs from 'dayjs';

const today = new Date();

const initState = {
  filter1: {
    phone: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  list_1543_items: [],
  list_1543_total: 0,
  temp: [],
  selected: undefined,
  countAssign: 0,
  history: [],
  totalHistory: 0,
  newCare: undefined,
  canCare: 0,
}

const moigoi1543Reducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_FILTER1:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filter1 !== 'undefined' && state.filter1) {
        if (action.data.page == 'no_update' && typeof state.filter1.page !== 'undefined') action.data.page = state.filter1.page;
        if (action.data.limit == 'no_update' && typeof state.filter1.limit !== 'undefined') action.data.limit = state.filter1.limit;
      }
      return {
        ...state,
        filter1: action.data,
      }
    }
    case MOIGOI1543_LIST_SUCCESS: {
      return {
        ...state,
        list_1543_items: action.data.items,
        list_1543_total: action.data.total,
      }
    }
    case MOIGOI1543_LAY_GOI_CUOC_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
      }
    }
    case LOOKUP_CLEANER: {
      return initState
    }
    default: {
      return state;
    }
  }
}
export default moigoi1543Reducer