import React, { useEffect, useState } from 'react';

import { Search as SearchIcon, Phone as PhoneIcon, FileText } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  MenuItem,
  Container
} from '@material-ui/core';

import Select from 'react-select';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { ReportchitietAction } from 'src/actions/reportchitiet.action';
import { AccountAction } from 'src/actions/account.acction';

import moment from 'moment';

import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ReportchitietListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.reportchitiet)
  const { items, total } = useSelector(state => state.account)
  const { role } = useSelector(state => state.profile)

  const [values, setValues] = useState({
    message: "",
    username: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      username: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(ReportchitietAction.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      username: values.username,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(ReportchitietAction.updateFilter(payload));
    dispatch(ReportchitietAction.list())
  };

  const exportExcel = async () => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        message: values.message,
        username: values.username,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(ReportchitietAction.updateFilter(payload));
      const response = await dispatch(ReportchitietAction.listExport());

      const data = [
        [
          '#',
          'Kênh',
          'Username nhân viên',
          'Tên nhân viên',
          'SĐT chăm sóc',
          'Gói cước',
          'Doanh thu',
          'Ngày thành công',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            count,
            element.kenh,
            element.username_nv,
            element.ten_nv,
            role.priority < 3 ? element.so_dien_thoai_cs.slice(-9) : ('****' + element.so_dien_thoai_cs.slice(-5)),
            element.goi_cuoc,
            element.doanh_thu,
            moment(element.ngay_thanh_cong).format('DD-MM-YYYY HH:mm:ss'),
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Bao-cao-doanh_thu_chi_tiet.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card" style={{ overflow: 'visible' }}>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                >
                  <div style={{ zIndex: '10' }} className="full-width-select">
                    <Select
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: 56,
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }),
                      }}
                      options={items}
                      getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                      placeholder={values.username || "Chọn nhân viên..."}
                      components={{
                        DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                        IndicatorSeparator: null,
                      }}
                      value={values.username || null}
                      onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                      isSearchable
                    />
                  </div>

                </Grid>

                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <PhoneIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    name="message"
                    onChange={handleMessageChange}
                    onKeyDown={handleKeyDown}
                    value={values.message}
                    placeholder="Tìm SĐT"
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                >
                  <DatePicker
                    fullWidth
                    label="Thời gian bắt đầu"
                    format='DD/MM/YYYY'
                    value={values.startDate}
                    onChange={(newValue) => handleStartDateChange(newValue)}
                    sx={{
                      '.MuiFormControl-root': {
                        width: '100%',
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                >
                  <DatePicker
                    fullWidth
                    label="Thời gian kết thúc"
                    value={values.endDate}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => handleEndDateChange(newValue)}
                  />
                </Grid>
              </Grid>

              {/* <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 56,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                    }}
                    options={items}
                    getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                    placeholder={values.username || "Chọn nhân viên xem doanh thu..."}
                    components={{
                      DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                      IndicatorSeparator: null,
                    }}
                    value={values.username || null}
                    onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                    isSearchable
                  />
                </div>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="message"
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyDown}
                  value={values.message}
                  placeholder="Tìm SĐT"
                  variant="outlined"
                  style={{ height: 56, width: 250, marginLeft: 24 }}
                />
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '100%', marginRight: 24 }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
              </Box> */}

              <div style={{ marginTop: 10 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={exportExcel}
                  style={{ height: 56, marginTop: 6, marginLeft: 0 }}
                  startIcon={<FileText size={15} />}
                >
                  Xuất báo cáo
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 6, marginLeft: 5 }}
                  startIcon={<SearchIcon size={15} />}
                >
                  Tra cứu
                </Button>
              </div>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default ReportchitietListToolbar;
