import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    //inputs' container
    ".filter_card .MuiBox-root": {
      height: "50px !important",
    },
    //container of each input
    ".filter_card .MuiFormControl-root": {
      height: "30px !important",
      width: "100%"
    },
    //general input
    ".filter_card .MuiOutlinedInput-root.MuiInputBase-root": {
      height: "30px !important",
      fontSize: "13px !important",
    },
    //Box search & select
    ".filter_card .full-width-select > div > div": {
      height: "30px !important",
      minHeight: "30px !important",
      fontSize: "13px !important",
    },
    //Button
    ".filter_card .MuiButton-root": {
      height: "30px !important",
      fontSize: "10px !important",
    },
    //DatePicker
    ".filter_card .MuiFormControl-root:has(button)": {
      marginTop: "5px !important",
    },
    ".filter_card.MuiCard-root": {
      boxShadow: "unset !important"
    },
    ".MuiTypography-h5": {
      fontSize: 'inherit !important',
    },
    ".nowrap": {
      whiteSpace: "nowrap"
    },
    "tbody > tr > td": {
      borderBottom: "unset !important",
      borderTop: '1px solid gray !important'
    },
    ".MuiTableCell-root.MuiTableCell-head": {
      padding: "16px 6px"
    },
    ".MuiTableCell-root.MuiTableCell-body": {
      padding: "16px 6px"
    }
  }
}));

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
