import { Helmet } from 'react-helmet';

import {
  Chip,
  Box,
  Grid,
  Card,
  Button,
  Divider,
  TextField,
  Container
} from '@material-ui/core';

import moment from 'moment';
import { Trash } from 'react-feather';
import { useState, useEffect } from 'react';
import { TinhluongAction } from 'src/actions/tinhluong.action';

import {
  useDispatch,
  useSelector,
} from 'react-redux'
import numeral from 'numeral';

const StaffSalaryConfig = () => {
  const dispatch = useDispatch()

  const [minCond, setMinCond] = useState({
    minProfit: "",
    minMethod: "SalaryProfitPercent",
    minSalary: "",
    commission: "0"
  })

  const [conditions, setConditions] = useState([])
  const [profitMonth, setProfitMonth] = useState([])
  const [profitMonthValue, setProfitMonthValue] = useState()

  useEffect(() => {
    const newArray = [...profitMonth]

    for (let i = 0; i < 6; i++) {
      newArray.push(moment().subtract(i, "month").format("MM/YYYY"))
    }

    setProfitMonth(newArray)
    onProfitMonthChange(newArray[0])
  }, [])

  const addNewCondition = () => {
    const newCondition = [...conditions]
    newCondition.push({
      min: "0",
      max: "0",
      firstOperator: ">",
      secondOperator: "<",
      commission: 0,
      basicSalary: 0
    })

    setConditions(newCondition)
  }

  const onMinConditionChange = (t, e, format = true, cast = Number) => {
    const _cdt = { ...minCond }
    _cdt[t] = format ? numeral(e.target.value).format('0,0') : cast(e.target.value)

    setMinCond(_cdt)
  }

  const onRangeConditionChange = (t, e, i, format = true, cast = Number) => {
    const _cdt = [...conditions]
    _cdt[i][t] = format ? numeral(e.target.value).format('0,0') : cast(e.target.value)

    setConditions(_cdt)
  }

  const onDeleteCondition = i => {
    const _cdt = [...conditions]
    _cdt.splice(i, 1)

    setConditions(_cdt)
  }

  const handleSaveConditions = () => {
    const minCondition = { ...minCond }
    minCondition.minProfit = Number(minCondition.minProfit.replace(/\,/g, ""))
    minCondition.minSalary = Number(minCondition.minSalary.replace(/\,/g, ""))
    minCondition.commission = Number(minCondition.commission.replace(/\,/g, ""))

    const payload = {}
    payload.profitMonth = profitMonthValue
    payload.minCondition = JSON.stringify(minCondition)

    payload.conditionList = JSON.stringify(conditions.map(c => {
      c.min = typeof c.min == "string" ? Number(c.min.replace(/\,/g, "")) : c.min
      c.max = typeof c.max == "string" ? Number(c.max.replace(/\,/g, "")) : c.max
      c.basicSalary = typeof c.basicSalary == "string" ? Number(c.basicSalary.replace(/\,/g, "")) : c.basicSalary

      return c
    }))

    dispatch(TinhluongAction.saveConditions(payload))
  }

  const onProfitMonthChange = async value => {
    setProfitMonthValue(value)

    const response = await dispatch(TinhluongAction.getConditions({ profitMonth: value }))

    if ("minCondition" in response.data) {
      const minCondition = JSON.parse(response.data.minCondition)
      minCondition.minProfit = numeral(minCondition.minProfit).format('0,0')
      minCondition.minSalary = numeral(minCondition.minSalary).format('0,0')
      minCondition.commission = numeral(minCondition.commission).format('0,0')

      setMinCond(minCondition)
    }


    if ("conditionList" in response.data) {
      const condList = JSON.parse(response.data.conditionList).map(c => {
        c.min = numeral(c.min).format('0,0')
        c.max = numeral(c.max).format('0,0')
        c.basicSalary = numeral(c.basicSalary).format('0,0')

        return c
      })

      setConditions(condList)
    } else {
      setConditions([])
    }
  }

  return <>
    <Helmet>
      <title>Cài đặt tính lương</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{ maxWidth: "100%" }}>
        <Card>
          <div style={{ marginBottom: 100 }}>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="Kỳ lương"
                name="role"
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
                onChange={e => onProfitMonthChange(e.target.value)}
                value={profitMonthValue}
              >
                {profitMonth.map((item) => (
                  <option>
                    {item}
                  </option>
                ))}
              </TextField>

            </Grid>

            <div>
              <Chip style={{ marginBottom: 10 }} label={`Điều kiện tối thiểu`} />

              <Grid
                container
                rowSpacing={1}
                spacing={3}
                alignItems="center"
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Khi doanh thu dưới"
                    value={minCond.minProfit}
                    onChange={e => onMinConditionChange("minProfit", e)}
                  />
                </Grid>

                <Grid
                  item
                  md={1}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Phương thức"
                    name="role"
                    required
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    value={minCond.minMethod}
                    onChange={e => onMinConditionChange("minMethod", e, false, String)}
                  >
                    <option value="SalaryProfitPercent">
                      % DTTL
                    </option>

                    <option value="SalaryNumber">
                      Cố định
                    </option>
                  </TextField>
                </Grid>
              </Grid>

              {/* Tiền lương, hoa hồng */}
              <Grid
                container
                spacing={3}
                style={{ marginTop: 10, marginBottom: 10 }}
                alignItems="center"
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label={minCond.minMethod == "SalaryProfitPercent" ? "% Doanh Thu Tính Lương" : "Lương cơ bản"}
                    onChange={e => onMinConditionChange('minSalary', e)}
                    required
                    value={minCond.minSalary}
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  md={1}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="% Hoa hồng"
                    onChange={e => onMinConditionChange('commission', e)}
                    required
                    value={minCond.commission}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>

            {conditions.map((item, index) =>
              <div key={index}>
                <Chip label={`Điều kiện ${index + 1}`} />

                <Grid
                  container
                  rowSpacing={1}
                  spacing={3}
                  style={{ marginTop: index == 0 ? 'unset' : 0 }}
                  alignItems="center"
                >
                  <Grid
                    item
                    md={1}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Biểu thức 1"
                      name="role"
                      required
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      value={item.firstOperator}
                      onChange={e => onRangeConditionChange('firstOperator', e, index, false, String)}
                    >
                      <option>
                        {'>'}
                      </option>

                      <option>
                        {'>='}
                      </option>
                    </TextField>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Từ"
                      onChange={e => onRangeConditionChange('min', e, index)}
                      required
                      value={item.min}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={1}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Biểu thức 2"
                      name="role"
                      required
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      value={item.secondOperator}
                      onChange={e => onRangeConditionChange('secondOperator', e, index, false, String)}
                    >
                      <option>
                        {'<'}
                      </option>

                      <option>
                        {'=<'}
                      </option>
                    </TextField>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    xs={12}
                    spacing={1}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        label="Đến"
                        onChange={e => onRangeConditionChange('max', e, index)}
                        required
                        value={item.max}
                        variant="outlined"
                      />

                      <Button
                        style={{ marginLeft: 10 }}
                        color="error"
                        variant="contained"
                        onClick={() => onDeleteCondition(index)}
                      >
                        <Trash />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                {/* Tiền lương, hoa hồng */}
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  alignItems="center"
                >
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Lương cơ bản"
                      onChange={e => onRangeConditionChange('basicSalary', e, index)}
                      required
                      value={item.basicSalary}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    md={1}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="% Hoa hồng"
                      onChange={e => onRangeConditionChange('commission', e, index, false)}
                      required
                      value={item.commission}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>)}

            <div style={{ marginTop: 15 }}>
              <Button

                color="info"
                variant="contained"
                onClick={addNewCondition}
              >
                Thêm điều kiện
              </Button>

              <Button
                style={{ marginLeft: 10 }}
                color="info"
                variant="contained"
                onClick={handleSaveConditions}
              >
                Lưu điều kiện
              </Button>
            </div>
          </div>

        </Card>
      </Container>
    </Box>
  </>
};

export default StaffSalaryConfig;
