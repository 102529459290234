import {
  useEffect,
  useState,
} from 'react';

import { showToast } from 'src/utils/common';

import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
  Check,
  X,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getRequestrefundStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Select from 'react-select';

import { Search as SearchIcon } from 'react-feather';

import CreateEditDialog from './CreateEditDialog';

import { isGrantPermission } from 'src/utils/utils';
import { BorderAll } from '@material-ui/icons';

import numeral from 'numeral';
import { GiahoantienAction } from 'src/actions/giahoantien.action';

import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const RequestrefundLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedRequestrefundIds, setSelectedRequestrefundIds] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const { items, total, filter } = useSelector(state => state.requestrefund);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)
  const [updateUserValue, setUpdateUserValue] = useState({});
  const { items: giahoantienItems, total: giahoantienTotal } = useSelector(state => state.giahoantien)
  const [updatePackageCode, setUpdatePackageCode] = useState({});
  const [updateTienHoanThemValue, setUpdateTienHoanThemValue] = useState({});
  const [updateTienHoanValue, setUpdateTienHoanValue] = useState({});
  const [updateTienChenhLechValue, setUpdateTienChenhLechValue] = useState({});

  const [QRCodeFormOpen, setQRCodeFormOpen] = useState(false)
  const [QRCodeAmount, setQRCodeAmount] = useState("0")
  const [QRCodeTarget, setQRCodeTarget] = useState("NULL")

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(RequestrefundAction.updateFilter(payload));
    dispatch(RequestrefundAction.list(page, limit))
    if (role.priority < 3) dispatch(GiahoantienAction.listNoLimit());
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedRequestrefundIds;

    if (event.target.checked) {
      newSelectedRequestrefundIds = items.map((requestrefund) => requestrefund.id);
    } else {
      newSelectedRequestrefundIds = [];
    }

    setSelectedRequestrefundIds(newSelectedRequestrefundIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRequestrefundIds.indexOf(id);
    let newSelectedRequestrefundIds = [];

    if (selectedIndex === -1) {
      newSelectedRequestrefundIds = newSelectedRequestrefundIds.concat(selectedRequestrefundIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRequestrefundIds = newSelectedRequestrefundIds.concat(selectedRequestrefundIds.slice(1));
    } else if (selectedIndex === selectedRequestrefundIds.length - 1) {
      newSelectedRequestrefundIds = newSelectedRequestrefundIds.concat(selectedRequestrefundIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRequestrefundIds = newSelectedRequestrefundIds.concat(
        selectedRequestrefundIds.slice(0, selectedIndex),
        selectedRequestrefundIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRequestrefundIds(newSelectedRequestrefundIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, requestrefund) => {
    setSelected(requestrefund);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(RequestrefundAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    console.log(id);
    dispatch(RequestrefundAction.findOne(id));
    setOpen(true);
  };

  const approve = async (id, status_duyet_don) => {
    const question = status_duyet_don == 1 ? "Xác nhận duyệt đơn này?" : "Xác nhận từ chối đơn này?";
    if (window.confirm(question)) {
      try {
        const currentOriginUrl = window.location.origin;
        const response = await dispatch(RequestrefundAction.approve(id, { currentOriginUrl, status_duyet_don }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const setTrangThaiDuyetHoanGoiStatus = async (id, trang_thai_duyet_hoan_goi) => {
    const question = trang_thai_duyet_hoan_goi == 1 ? "Xác nhận chọn trạng thái 'ĐÃ DUYỆT HOÀN'?" : "Xác nhận chọn trạng thái 'CHƯA DUYỆT HOÀN'?";
    if (window.confirm(question)) {
      try {
        const currentOriginUrl = window.location.origin;
        const response = await dispatch(RequestrefundAction.setTrangThaiDuyetHoanGoiStatus(id, { currentOriginUrl, trang_thai_duyet_hoan_goi }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const setTransferStatus = async (id, transferStatus) => {
    const question = transferStatus == 1 ? "Xác nhận chọn trạng thái 'ĐÃ CHUYỂN KHOẢN'?" : "Xác nhận chọn trạng thái 'CHƯA CHUYỂN KHOẢN'?";
    if (window.confirm(question)) {
      try {
        const response = await dispatch(RequestrefundAction.setTransferStatus(id, { is_transfer: transferStatus }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const setTrangThaiGiaoDich = async (id, trangThaiGiaoDich) => {
    const question = trangThaiGiaoDich == -9999 ? "Xác nhận chọn trạng thái 'HOÀN THÀNH'?" : (trangThaiGiaoDich == -10002 ? "Xác nhận chọn trạng thái 'ĐANG XỬ LÝ'?" : "Xác nhận chọn trạng thái 'HOÀN TIỀN'?");
    if (window.confirm(question)) {
      try {
        const response = await dispatch(RequestrefundAction.setTrangThaiGiaoDich(id, { ma_trang_thai_giao_dich_doi_tac: trangThaiGiaoDich }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const setHinhThuc = async (id, request_method) => {
    let option = ""
    if (request_method == 1) option = "GIA HẠN";
    else if (request_method == 2) option = "NÂNG CẤP T1";
    else if (request_method == 3) option = "NÂNG CẤP T2";
    else if (request_method == 4) option = "NÂNG CẤP T3";
    else if (request_method == 10) option = "NÂNG CẤP G1";
    else if (request_method == 11) option = "NÂNG CẤP G2";
    else if (request_method == 12) option = "NÂNG CẤP G3";
    let question = `Xác nhận chọn hình thức '${option}'?`;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(RequestrefundAction.setHinhThuc(id, { request_method }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const setYeuCau = async (id, request_type) => {
    let option = ""
    if (request_type == 1) option = "HOÀN TRƯỚC";
    else if (request_type == 2) option = "HOÀN SAU";
    else if (request_type == 3) option = "KHÔNG HOÀN";
    else if (request_type == 4) option = "HOÀN THÊM";
    let question = `Xác nhận chọn yêu cầu '${option}'?`;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(RequestrefundAction.setYeuCau(id, { request_type }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '" + response.channel + "']\n" + response.message + (response.status != "Unknown" ? (" (" + response.status + ")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n" + response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
      }
    } else {

    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const date = new Date(); // Current date and time

  // Formatting options
  const date_options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const handleTienHoanChange = (event, refund_price, id) => {
    refund_price = refund_price.trim();
    if (refund_price != '') {
      refund_price = refund_price.replace(/,/g, ''); // Remove existing commas
      //refund_price = Number(refund_price).toLocaleString(); // Format with commas
      refund_price = numeral(refund_price).format('0,0');
      setUpdateTienHoanValue({
        ...updateTienHoanValue,
        [id]: refund_price,
      });
    }
  }

  const handleTienHoanChangeConfirm = (event, refund_price, id) => {
    if (event.key === 'Enter') {
      refund_price = refund_price.trim();
      if (refund_price != '') {
        refund_price = refund_price.replace(/,/g, '');
        handleTienHoanChange(event, refund_price, id)
        dispatch(RequestrefundAction.updateTienHoan(refund_price, id));
      }
    }
  };

  const handleTienHoanThemChange = (event, other_refund, id) => {
    other_refund = other_refund.trim();
    if (other_refund != '') {
      other_refund = other_refund.replace(/,/g, ''); // Remove existing commas
      //other_refund = Number(other_refund).toLocaleString(); // Format with commas
      other_refund = numeral(other_refund).format('0,0');
      setUpdateTienHoanThemValue({
        ...updateTienHoanThemValue,
        [id]: other_refund,
      });
    }
  }

  const handleTienHoanThemChangeConfirm = (event, other_refund, id) => {
    if (event.key === 'Enter') {
      other_refund = other_refund.trim();
      if (other_refund != '') {
        other_refund = other_refund.replace(/,/g, '');
        handleTienHoanThemChange(event, other_refund, id)
        dispatch(RequestrefundAction.updateTienHoanThem(other_refund, id));
      }
    }
  };

  const handleTienChenhLechChange = (event, chenh_lech, id) => {
    chenh_lech = chenh_lech.trim();
    if (chenh_lech != '') {
      chenh_lech = chenh_lech.replace(/,/g, ''); // Remove existing commas
      //chenh_lech = Number(chenh_lech).toLocaleString(); // Format with commas
      chenh_lech = numeral(chenh_lech).format('0,0');
      setUpdateTienChenhLechValue({
        ...updateTienChenhLechValue,
        [id]: chenh_lech,
      });
    }
  }

  const handleTienChenhLechChangeConfirm = (event, chenh_lech, id) => {
    if (event.key === 'Enter') {
      chenh_lech = chenh_lech.trim();
      if (chenh_lech != '') {
        chenh_lech = chenh_lech.replace(/,/g, '');
        handleTienChenhLechChange(event, chenh_lech, id)
        dispatch(RequestrefundAction.updateTienChenhLech(chenh_lech, id));
      }
    }
  };

  const handleUserChange = (event, username, id) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [id]: username,
      });
    }
  }

  const handleUserChangeConfirm = (event, username, id) => {
    if (event.key === 'Enter') {
      username = username.trim();
      if (username != '') {
        handleUserChange(event, username, id)
        dispatch(RequestrefundAction.updateUser(username, id));
      }
    }
  };

  const handlePackageCodeChangeConfirm = (package_code, id) => {
    console.log(package_code, id)
    package_code = package_code.trim();
    if (package_code != '') {
      setUpdatePackageCode({
        ...updatePackageCode,
        [id]: package_code,
      });
      dispatch(RequestrefundAction.updatePackageCode(package_code, id));
    }
  };

  const setCreatedTime = async (id, created_time) => {
    console.log(id, created_time)

    const result = await dispatch(RequestrefundAction.setCreatedTime(id, { created_time }));
  };

  const createQRCode = (target, amount) => {
    target = "0" + target
    amount = numeral(amount).format('0,0')

    setQRCodeAmount(amount)
    setQRCodeTarget(target)

    setQRCodeFormOpen(true)
  }


  const closeQRCode = () => {
    setQRCodeAmount("0")
    setQRCodeTarget("NULL")

    setQRCodeFormOpen(false)
  }

  return (
    <>
      <Card {...rest} style={{ width: "100%" }}>
        <PerfectScrollbar>
          {/* <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => createQRCode("0332211065", "500000")}
          >
            Tạo QR
          </Button> */}


          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {1 < 0 && <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRequestrefundIds.length === items.length}
                      color="primary"
                      indeterminate={
                        selectedRequestrefundIds.length > 0
                        && selectedRequestrefundIds.length < items.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>}
                  <TableCell>
                    #
                  </TableCell>
                  <TableCell className="nowrap">
                    Username
                  </TableCell>
                  <TableCell className="nowrap">
                    Tên NV
                  </TableCell>
                  <TableCell className="nowrap">
                    SĐT - Loại TB
                  </TableCell>
                  <TableCell className="nowrap">
                    Gói cước
                  </TableCell>
                  <TableCell className="nowrap">
                    Trạng thái
                  </TableCell>
                  {1 < 0 && <TableCell className="nowrap">
                    Ghi nhận báo cáo
                  </TableCell>}
                  <TableCell className="nowrap">
                    Yêu cầu
                  </TableCell>
                  <TableCell className="nowrap">
                    Hình thức
                  </TableCell>
                  <TableCell className="nowrap">
                    HOÀN hoặc NẠP
                  </TableCell>
                  <TableCell className="nowrap">
                    Hoàn thêm
                  </TableCell>
                  <TableCell className="nowrap">
                    Số tiền sau CK
                  </TableCell>
                  <TableCell className="nowrap">
                    Chênh lệch CK
                  </TableCell>
                  <TableCell className="nowrap">
                    Tiến trình nạp
                  </TableCell>
                  <TableCell className="nowrap">
                    Thời gian yêu cầu
                  </TableCell>
                  {role.priority < 3 && <TableCell className="nowrap">
                    Kênh đối tác
                  </TableCell>}
                  {/* <TableCell style={{ width: '200px' }}>
                    Trạng thái chuyển khoản
                  </TableCell> */}
                  <TableCell className="nowrap">
                    NV duyệt nạp tiền
                  </TableCell>
                  <TableCell className="nowrap">
                    Trạng thái duyệt hoàn gói
                  </TableCell>
                  <TableCell className="nowrap">
                    Chức năng
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.slice(page * limit, page * limit + limit).map((requestrefund, index) => (
                  <TableRow
                    hover
                    key={requestrefund.id}
                    selected={selectedRequestrefundIds.indexOf(requestrefund.id) !== -1}
                  >
                    {1 < 0 && <TableCell padding="checkbox" style={{ border: '0.5px solid white' }}>
                      <Checkbox
                        checked={selectedRequestrefundIds.indexOf(requestrefund.id) !== -1}
                        onChange={(event) => handleSelectOne(event, requestrefund.id)}
                        value="true"
                      />
                    </TableCell>}
                    <TableCell>
                      {page * limit + index + 1}
                    </TableCell>
                    <TableCell className="nowrap">
                      {role.priority < 3 && <TextField
                        name={requestrefund.id}
                        onChange={(e) => handleUserChange(e, e.target.value, requestrefund.id)}
                        onKeyDown={(e) => handleUserChangeConfirm(e, e.target.value, requestrefund.id)}
                        value={updateUserValue[requestrefund.id] || requestrefund.staff_username}
                        placeholder=""
                        variant="outlined"
                        style={{ height: 56, width: 150, marginLeft: 0 }}
                      />}
                      {role.priority > 2 && requestrefund.staff_username}
                    </TableCell>
                    <TableCell className="nowrap">
                      {requestrefund.staff_fullname}
                    </TableCell>
                    <TableCell className="nowrap">
                      <Button style={{ padding: 0 }} onClick={() => copyToClipboard(requestrefund.number_phone.slice(-9))}>
                        {role.priority < 3 ? requestrefund.number_phone.slice(-9) : ('****' + requestrefund.number_phone.slice(-5))}
                      </Button>
                      {requestrefund.number_type == 1 ? " - MC" : " - MG"}
                    </TableCell>
                    <TableCell className="nowrap">
                      {role.priority < 3 && <div style={{ width: '120px', zIndex: '10' }} className="full-width-select">
                        <Select
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: 56,
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }),
                          }}
                          options={giahoantienItems}
                          getOptionLabel={(item) => `${item.ten_goi_cuoc}`}
                          placeholder={updatePackageCode[requestrefund.id] || requestrefund.package_code || "Chọn gói..."}
                          components={{
                            DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                            IndicatorSeparator: null,
                          }}
                          value={updatePackageCode[requestrefund.id] || requestrefund.package_code || null}
                          onChange={(newValue) => handlePackageCodeChangeConfirm(newValue.ten_goi_cuoc, requestrefund.id)}
                          isSearchable
                        />
                      </div>}
                      {role.priority > 2 && requestrefund.package_code}
                    </TableCell>
                    <TableCell className="nowrap">
                      <Select1 style={{ fontSize: "14px", width: "100%", color: (requestrefund.trang_thai_giao_dich_doi_tac == "Hoàn thành" ? 'green' : (requestrefund.trang_thai_giao_dich_doi_tac != "Hoàn tiền" && requestrefund.trang_thai_giao_dich_doi_tac != "Đơn bị từ chối" ? 'blue' : 'red')) }} variant="outlined" value={-99} onChange={(e) => setTrangThaiGiaoDich(requestrefund.id, e.target.value)}>
                        <MenuItem value="-99">{requestrefund.trang_thai_giao_dich_doi_tac}</MenuItem>
                        {requestrefund.trang_thai_giao_dich_doi_tac != "Hoàn thành" && <MenuItem value="-9999">Hoàn thành</MenuItem>}
                        {requestrefund.trang_thai_giao_dich_doi_tac != "Hoàn tiền" && <MenuItem value="-10000">Hoàn tiền</MenuItem>}
                        {requestrefund.number_type != 1 && requestrefund.trang_thai_giao_dich_doi_tac != "Đang xử lý" && <MenuItem value="-10002">Đang xử lý</MenuItem>}
                      </Select1>
                      {1 < 0 && requestrefund.request_state == -1 && "Đã từ chối"}
                      {1 < 0 && requestrefund.request_state == 0 && "Chưa xử lý"}
                      {1 < 0 && requestrefund.request_state == 1 && "Đang xử lý"}
                      {1 < 0 && requestrefund.request_state == 2 && "Đã hoàn"}
                      {1 < 0 && requestrefund.request_state == 3 && "Truy thu"}
                      {1 < 0 && requestrefund.request_state == 4 && "Đã thu"}
                      {1 < 0 && requestrefund.request_state == 5 && "Đã kết thúc"}
                      {1 < 0 && requestrefund.request_state == 6 && "Cần phê duyệt"}
                      {1 < 0 && requestrefund.request_state == 7 && "Chờ xem xét"}
                    </TableCell>
                    {1 < 0 && <TableCell>
                      {requestrefund.request_status == 1 ? "Đã ghi nhận" : "Chưa ghi nhận"}
                    </TableCell>}
                    <TableCell className="nowrap">
                      {role.priority > 2 && <>
                        {requestrefund.request_type == 1 && "Hoàn trước"}
                        {requestrefund.request_type == 2 && "Hoàn sau"}
                        {requestrefund.request_type == 3 && "Không hoàn"}
                        {requestrefund.request_type == 4 && "Hoàn thêm"}
                        {requestrefund.request_type == 5 && "Nạp tiền"}
                      </>}
                      {role.priority < 3 && <>
                        <Select1 style={{ fontSize: "14px", width: "100%" }} variant="outlined" value={requestrefund.request_type} onChange={(e) => setYeuCau(requestrefund.id, e.target.value)}>
                          <MenuItem value="1">Hoàn trước</MenuItem>
                          <MenuItem value="2">Hoàn sau</MenuItem>
                          {1 < 0 && <MenuItem value="3">Không hoàn</MenuItem>}
                          <MenuItem value="4">Hoàn thêm</MenuItem>
                          <MenuItem value="5">Nạp tiền</MenuItem>
                        </Select1>
                      </>}
                    </TableCell>
                    <TableCell className="nowrap">
                      {requestrefund.request_type != 5 && <>
                        {role.priority > 2 && <>
                          {requestrefund.request_method == 1 && "Gia hạn"}
                          {requestrefund.request_method == 2 && "Nâng cấp T1"}
                          {requestrefund.request_method == 3 && "Nâng cấp T2"}
                          {requestrefund.request_method == 4 && "Nâng cấp T3"}
                          {requestrefund.request_method == 10 && "Nâng cấp G1"}
                          {requestrefund.request_method == 11 && "Nâng cấp G2"}
                          {requestrefund.request_method == 12 && "Nâng cấp G3"}
                        </>}
                        {role.priority < 3 && <>
                          <Select1 style={{ fontSize: "14px", width: "100%" }} variant="outlined" value={requestrefund.request_method} onChange={(e) => setHinhThuc(requestrefund.id, e.target.value)}>
                            <MenuItem value="1">Gia hạn</MenuItem>
                            <MenuItem value="2">Nâng cấp T1</MenuItem>
                            <MenuItem value="3">Nâng cấp T2</MenuItem>
                            <MenuItem value="4">Nâng cấp T3</MenuItem>
                            <MenuItem value="10">Nâng cấp G1</MenuItem>
                            <MenuItem value="11">Nâng cấp G2</MenuItem>
                            <MenuItem value="12">Nâng cấp G3</MenuItem>
                          </Select1>
                          {1 < 0 && requestrefund.chenh_lech_hoan_tien_hinh_thuc_1 > 0 && ("Chênh lệch lần 1: " + requestrefund.chenh_lech_hoan_tien_hinh_thuc_1)}<br></br>
                          {1 < 0 && requestrefund.chenh_lech_hoan_tien_hinh_thuc_2 > 0 && ("Chênh lệch lần 2: " + requestrefund.chenh_lech_hoan_tien_hinh_thuc_2)}
                        </>}
                      </>}
                    </TableCell>
                    <TableCell className="nowrap">
                      {role.priority < 3 && requestrefund.request_type == 5 && <TextField
                        name={requestrefund.id}
                        onChange={(e) => handleTienHoanChange(e, e.target.value, requestrefund.id)}
                        onKeyDown={(e) => handleTienHoanChangeConfirm(e, e.target.value, requestrefund.id)}
                        value={updateTienHoanValue[requestrefund.id] || numeral(requestrefund.refund_price).format('0,0')}
                        placeholder=""
                        variant="outlined"
                        style={{ height: 56, width: 100, marginLeft: 0 }}
                      />}
                      {(role.priority > 2 || requestrefund.request_type != 5) && numeral(requestrefund.refund_price).format('0,0')}
                    </TableCell>
                    <TableCell className="nowrap">
                      {role.priority < 3 && <TextField
                        name={requestrefund.id}
                        onChange={(e) => handleTienHoanThemChange(e, e.target.value, requestrefund.id)}
                        onKeyDown={(e) => handleTienHoanThemChangeConfirm(e, e.target.value, requestrefund.id)}
                        value={updateTienHoanThemValue[requestrefund.id] || numeral(requestrefund.other_refund).format('0,0')}
                        placeholder=""
                        variant="outlined"
                        style={{ height: 56, width: 100, marginLeft: 0 }}
                      />}
                      {role.priority > 2 && numeral(requestrefund.other_refund).format('0,0')}
                    </TableCell>
                    {requestrefund.request_type == 5 && <TableCell className="nowrap">
                      {numeral(requestrefund.transfer_final_amount).format('0,0')}<br /><br />{"(Tỉ lệ: " + (requestrefund.ti_le_chiet_khau != 0 ? requestrefund.ti_le_chiet_khau : (requestrefund.refund_price != 0 ? parseInt((requestrefund.refund_price - requestrefund.transfer_final_amount) / requestrefund.refund_price * 100) : 0)) + "%)"}
                    </TableCell>}
                    {requestrefund.request_type != 5 && <TableCell className="nowrap">
                      {role.priority < 3 && <>{numeral(requestrefund.tien_hoan_sau_chiet_khau).format('0,0')}<br /><br />{"(Tỉ lệ: " + (requestrefund.ti_le_chiet_khau_hoan_tien != 0 ? requestrefund.ti_le_chiet_khau_hoan_tien : 0) + "%)"}</>}
                    </TableCell>}

                    {role.priority >= 3 && <TableCell className="nowrap" style={{ color: requestrefund.chenh_lech < 0 ? 'red' : (requestrefund.chenh_lech > 0 ? 'green' : 'black') }}>
                      {numeral(requestrefund.chenh_lech).format('0,0')}
                    </TableCell>}
                    {role.priority < 3 && <TableCell className="nowrap">
                      <TextField
                        name={requestrefund.id}
                        onChange={(e) => handleTienChenhLechChange(e, e.target.value, requestrefund.id)}
                        onKeyDown={(e) => handleTienChenhLechChangeConfirm(e, e.target.value, requestrefund.id)}
                        value={updateTienChenhLechValue[requestrefund.id] || numeral(requestrefund.chenh_lech).format('0,0')}
                        placeholder=""
                        variant="outlined"
                        sx={{ input: { color: requestrefund.chenh_lech < 0 ? 'red' : (requestrefund.chenh_lech > 0 ? 'green' : 'black') } }}
                        style={{ height: 56, width: 100, marginLeft: 0 }}
                      />
                      <br />
                      Người duyệt: {requestrefund.username_cap_nhat_chenh_lech}
                    </TableCell>}

                    <TableCell className="nowrap">
                      {numeral(requestrefund.tien_trinh_nap != 0 ? requestrefund.tien_trinh_nap : (requestrefund.trang_thai_giao_dich_doi_tac == 'Hoàn thành' ? (parseInt(requestrefund.request_type.toString()) != 4 ? (requestrefund.refund_price + requestrefund.other_refund) : requestrefund.other_refund) : 0)).format('0,0')}
                    </TableCell>
                    <TableCell className="nowrap">
                      {role.priority < 3 && <div style={{ width: '230px' }}><LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                        <DateTimePicker
                          value={requestrefund.created_time ? dayjs(new Date(requestrefund.created_time)) : null}
                          onChange={(newValue) => setCreatedTime(requestrefund.id, newValue)}
                        />
                      </LocalizationProvider></div>}
                      {role.priority > 2 && moment(requestrefund.created_time).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    {role.priority < 3 && <TableCell className="nowrap">
                      {requestrefund.kenh_doi_tac}
                    </TableCell>}
                    {/* <TableCell>
                      {requestrefund.request_type == 5 && <><Select1 style={{ fontSize: "14px", width: "100%", marginBottom: '15px', color: (requestrefund.is_transfer == 1 ? 'green' : 'red') }} variant="outlined" value={requestrefund.is_transfer || 0} onChange={(e) => setTransferStatus(requestrefund.id, e.target.value)}>
                        <MenuItem value="0">Chưa CK</MenuItem>
                        <MenuItem value="1">Đã CK</MenuItem>
                      </Select1>
                        Người duyệt: {requestrefund.username_duyet_trang_thai_chuyen_khoan}</>}
                    </TableCell> */}
                    <TableCell className="nowrap">
                      {requestrefund.approver}
                    </TableCell>
                    <TableCell className="nowrap">
                      {requestrefund.request_type <= 2 && <><Select1 style={{ fontSize: "14px", width: "100%", marginBottom: '15px', color: (requestrefund.trang_thai_duyet_hoan_goi == 1 ? 'green' : (requestrefund.trang_thai_duyet_hoan_goi != 2 ? 'blue' : 'red')) }} variant="outlined" value={requestrefund.trang_thai_duyet_hoan_goi || 0} onChange={(e) => setTrangThaiDuyetHoanGoiStatus(requestrefund.id, e.target.value)}>
                        <MenuItem value="0">Chưa duyệt hoàn</MenuItem>
                        <MenuItem value="1">Đã duyệt hoàn</MenuItem>
                        <MenuItem value="2">Từ chối</MenuItem>
                      </Select1>
                        <br />
                        Người duyệt: <span style={{ fontWeight: 'bold', color: requestrefund.username_duyet_trang_thai_duyet_hoan_goi != '__AUTO' ? 'blue' : 'gray' }}>{requestrefund.username_duyet_trang_thai_duyet_hoan_goi}</span></>}
                    </TableCell>
                    <TableCell className="nowrap">
                      {(requestrefund.approver == null || requestrefund.approver == "") && requestrefund.request_type == 5 && isGrantPermission(permissions, ["requestrefund.duyet-don-chuyen-khoan"]) && <IconButton style={{ border: "1px solid rgb(0,0,0,0.5)", margin: "2px" }} aria-label="approve" onClick={() => approve(requestrefund.id, 1)}>
                        <Check color='green' />
                      </IconButton>}
                      {(requestrefund.approver == null || requestrefund.approver == "") && requestrefund.request_type == 5 && isGrantPermission(permissions, ["requestrefund.duyet-don-chuyen-khoan"]) && <IconButton style={{ border: "1px solid rgb(0,0,0,0.5)", margin: "2px" }} aria-label="approve" onClick={() => approve(requestrefund.id, 2)}>
                        <X color='red' />
                      </IconButton>}
                      {1 < 0 && <IconButton aria-label="edit" onClick={() => onSelected(requestrefund.id)}>
                        <Edit3Icon color='green' />
                      </IconButton>}
                      {1 < 0 && <IconButton aria-label="delete" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, requestrefund)}>
                        <Trash2Icon color='red' />
                      </IconButton>}

                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => createQRCode(requestrefund.number_phone.concat(" ").concat(requestrefund.package_code), requestrefund.transfer_final_amount != "0" ? requestrefund.transfer_final_amount : requestrefund.tien_hoan_sau_chiet_khau)}
                      >
                        Tạo QR
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
        />
        <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
        <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
      </Card>


      <Dialog open={QRCodeFormOpen} onClose={closeQRCode}>
        <DialogTitle>QR Code</DialogTitle>
        <DialogContent style={{ width: 600, paddingTop: 20 }}>
          <Box>
            <TextField
              fullWidth
              label="Thuê bao"
              onChange={(e) => setQRCodeTarget(e.target.value)}
              value={QRCodeTarget}
              variant="outlined"
              inputProps={
                { readOnly: true }
              }
            />
          </Box>


          <Box style={{ marginTop: 15 }}>
            <TextField
              fullWidth
              label="Số tiền"
              onChange={(e) => { let value = e.target.value; value = numeral(value).format('0,0'); setQRCodeAmount(value) }}
              value={QRCodeAmount}
              variant="outlined"
            />
          </Box>

          <Box style={{ marginTop: 15 }}>
            <center>
              <img style={{ width: '60%' }} src={`https://img.vietqr.io/image/TCB-18001800-qr_only.jpg?amount=${QRCodeAmount.replace(/\,/g, "")}&addInfo=${QRCodeTarget} QRVTDD`} />
            </center>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeQRCode}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestrefundLookupDetail;
