import { ReportService } from 'src/services/report.service';
import { DEFAULT_PAGE } from 'src/utils/constants';

import { AppAction } from './app.action';

export const REPORT_9332_QUERY = 'REPORT:FIND_ALL_9332_QUERY'
export const REPORT_9332_SUCCESS = 'REPORT:FIND_ALL_9332_SUCCESS'
export const REPORT_9332_FAILD = 'REPORT:FIND_ALL_9332_FAILD'

export const REPORT_9084_QUERY = 'REPORT:FIND_ALL_9084_QUERY'
export const REPORT_9084_SUCCESS = 'REPORT:FIND_ALL_9084_SUCCESS'
export const REPORT_9084_FAILD = 'REPORT:FIND_ALL_9084_FAILD'

export const REPORT_9084new_QUERY = 'REPORT:FIND_ALL_9084new_QUERY'
export const REPORT_9084new_SUCCESS = 'REPORT:FIND_ALL_9084new_SUCCESS'
export const REPORT_9084new_FAILD = 'REPORT:FIND_ALL_9084new_FAILD'

export const ReportAction = {
  list9332,
  list9084,
  list9084new,
}

function list9332(payload) {
  return (dispatch, getState) => {
    const { report } = getState()
    dispatch(AppAction.appLoading(true));
    dispatch(query(payload))
    ReportService.report9332(payload).then((rs) => {
      if (payload.page === DEFAULT_PAGE || payload.page === `${DEFAULT_PAGE}`) {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        dispatch(success({
          items: [...report.items9332, ...rs.data.values],
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function query(data) { return { type: REPORT_9332_QUERY, data } }
  function success(data) { return { type: REPORT_9332_SUCCESS, data } }
  function failure(error) { return { type: REPORT_9332_FAILD, error } }
}

function list9084(payload) {
  return (dispatch, getState) => {
    const { report } = getState()
    dispatch(AppAction.appLoading(true));
    dispatch(query(payload))
    ReportService.report9084(payload).then((rs) => {
      if (payload.page === DEFAULT_PAGE || payload.page === `${DEFAULT_PAGE}`) {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        dispatch(success({
          items: [...report.items9084, ...rs.data.values],
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function query(data) { return { type: REPORT_9084_QUERY, data } }
  function success(data) { return { type: REPORT_9084_SUCCESS, data } }
  function failure(error) { return { type: REPORT_9084_FAILD, error } }
}

function list9084new(payload) {
  return (dispatch, getState) => {
    const { report } = getState()
    dispatch(AppAction.appLoading(true));
    dispatch(query(payload))
    ReportService.report9084new(payload).then((rs) => {
      if (payload.page === DEFAULT_PAGE || payload.page === `${DEFAULT_PAGE}`) {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        dispatch(success({
          items: [...report.items9084new, ...rs.data.values],
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      dispatch(failure(error));
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function query(data) { return { type: REPORT_9084new_QUERY, data } }
  function success(data) { return { type: REPORT_9084new_SUCCESS, data } }
  function failure(error) { return { type: REPORT_9084new_FAILD, error } }
}