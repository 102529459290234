import { Moigoi1543Service } from 'src/services/moigoi1543.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';
import { DEFAULT_PAGE, DEFAULT_ROW_PER_PAGE } from 'src/utils/constants';
import { AppAction } from './app.action';import moment from 'moment';

import MainSocket from 'src/components/MainSocket.js'

export const MOIGOI1543_LAY_GOI_CUOC_SUCCESS = 'MOIGOI1543:LAY_GOI_CUOC_SUCCESS'
export const MOIGOI1543_LAY_GOI_CUOC_FAILD = 'MOIGOI1543:LAY_GOI_CUOC_ALL_FAILD'

export const MOIGOI1543_MOI_GOI_CUOC_SUCCESS = 'MOIGOI1543:MOI_GOI_CUOC_SUCCESS'
export const MOIGOI1543_MOI_GOI_CUOC_FAILD = 'MOIGOI1543:MOI_GOI_CUOC_ALL_FAILD'

export const MOIGOI1543_LIST_SUCCESS = 'MOIGOI1543:LIST_SUCCESS'
export const MOIGOI1543_LIST_FAILD = 'MOIGOI1543:LIST_FAILD'

export const UPDATE_FILTER1 = 'MOIGOI1543:UPDATE_FILTER1'

export const Moigoi1543Action = {
    getMaMoiGoi,
    layGoiCuoc,
    moiGoiCuoc,
    list,
    updateFilter1,
}

function updateFilter1(payload) {
    return { type: UPDATE_FILTER1, data: payload }
}

function layGoiCuoc() {
    return (dispatch, getState) => {
        const { moigoi1543 } = getState()
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            Moigoi1543Service.layGoiCuoc().then((response) => {
                dispatch(success({
                    items: response.data.values,
                    total: response.data.count
                }));
                resolve(response);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: MOIGOI1543_LAY_GOI_CUOC_SUCCESS, data } }
    function failure(error) { return { type: MOIGOI1543_LAY_GOI_CUOC_FAILD, error } }
}

function getMaMoiGoi() {
    return (dispatch, getState) => {
        const { moigoi1543 } = getState()
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            Moigoi1543Service.getMaMoiGoi().then((response) => {
                resolve(response);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: MOIGOI1543_LAY_GOI_CUOC_SUCCESS, data } }
    function failure(error) { return { type: MOIGOI1543_LAY_GOI_CUOC_FAILD, error } }
}

function moiGoiCuoc(payload) {
    return (dispatch, getState) => {
        const { socket, socket_client_id, domain, username_nv } = MainSocket(getState());
        const { moigoi1543 } = getState()
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            Moigoi1543Service.moiGoiCuoc(payload).then((response) => {
                if (typeof response.data !== 'undefined' && response.data && typeof response.data.data !== 'undefined' && response.data.data && typeof response.data.data.message !== 'undefined' && response.data.data.message && typeof response.data.data.error_code !== 'undefined') {
                    if (response.data.data.error_code == "0") {
                        showToast(NOTI_TYPES.SUCCESS, "Tiếp nhận lời mời thành công")
                        socket.emit("showThongBao", {domain: domain, content: username_nv.substring(0, 5) + "***** đã mời thành công gói " + payload.package.toUpperCase() + " cho *****" + payload.phone.slice(-3)})
                    }
                    else showToast(NOTI_TYPES.ERROR, response.data.data.message)
                }
                dispatch(Moigoi1543Action.list({
                    endDate: moment(new Date()).format('DD/MM/YYYY'),
                    status: '',
                    startDate: moment(new Date()).format('DD/MM/YYYY'),
                    user: '',
                    page: DEFAULT_PAGE,
                    count: DEFAULT_ROW_PER_PAGE
                }))
                dispatch(success(response));
                resolve(response);
            }).catch((error) => {
                showToast(NOTI_TYPES.ERROR, 'Unknown');
                dispatch(Moigoi1543Action.list({
                    endDate: moment(new Date()).format('DD/MM/YYYY'),
                    status: '',
                    startDate: moment(new Date()).format('DD/MM/YYYY'),
                    user: '',
                    page: DEFAULT_PAGE,
                    count: DEFAULT_ROW_PER_PAGE
                }))
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        })
    };
    function success(data) { return { type: MOIGOI1543_MOI_GOI_CUOC_SUCCESS, data } }
    function failure(error) { return { type: MOIGOI1543_MOI_GOI_CUOC_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        const { moigoi1543 } = getState();
        dispatch(AppAction.appLoading(true));
  
        const { filter1 } = moigoi1543;
        const page = filter1.page;
        const limit = filter1.limit;
  
        Moigoi1543Service.findAll(filter1.page, filter1.limit, filter1).then((rs) => {
            if (!!filter1 && filter1.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = moigoi1543.list_1543_items;
                const rsDataValues = rs.data.values;
  
                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOIGOI1543_LIST_SUCCESS, data } }
    function failure(error) { return { type: MOIGOI1543_LIST_FAILD, error } }
  }