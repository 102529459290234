import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Search as SearchIcon, FileText as FileTextIcon, Phone as PhoneIcon, Tag as TagIcon, CreditCard } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import * as XLSX from 'xlsx';

import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';

import CreateEditDialogSelectChannel from './CreateEditDialogSelectChannel';
import CreateEditDialog from './CreateEditDialog';
import CreateEditDialog1 from './CreateEditDialog1';
import { SettingAction } from 'src/actions/setting.action';
import { isGrantPermission } from 'src/utils/utils';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import moment from 'moment';
import { saveAs } from 'file-saver';

const RequestrefundToolbar = (props) => {
  const dispatch = useDispatch()
  const [openSelectChannel, setOpenSelectChannel] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);
  const { filter } = useSelector(state => state.requestrefund)

  const fileInput = React.useRef();

  const { info, permissions, role } = useSelector(state => state.profile)

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      message: "",
      username: "",
      trang_thai_giao_dich_doi_tac: "",
      is_transfer: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(RequestrefundAction.updateFilter(payload));
  }, []);

  const [values, setValues] = useState({
    message: "",
    username: "",
    trang_thai_giao_dich_doi_tac: "",
    is_transfer: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleClickOpenSelectChannel = () => {
    setOpenSelectChannel(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(1).map((c) => ({
        phone: c[1],
        type: c[2],
        package: c[3],
        registerAt: c[4],
        expiredAt: c[5],
        packageCycle: c[6],
        state: c[7],
        status: "NORMAL",
        packageCanRegister: c[8]
      }));
      dispatch(RequestrefundAction.importData(temp));
      dispatch(AppAction.appLoading(false))
      setPreview(true)
      e.target.value = null;
    };
    reader.readAsBinaryString(f)
  }

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(RequestrefundAction.countAssign())
    setAssign(true)
  }

  const handleCloseSelectChannel = () => {
    setOpenSelectChannel(false);
  };

  const handleClose = () => {
    setOpen(false);
    console.log(role);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleTrangThaiGiaoDichDoiTacChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleIsTransferChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      message: values.message,
      trang_thai_giao_dich_doi_tac: values.trang_thai_giao_dich_doi_tac,
      is_transfer: values.is_transfer,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(RequestrefundAction.updateFilter(payload));
    dispatch(RequestrefundAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        message: values.message,
        username: values.username,
        trang_thai_giao_dich_doi_tac: values.trang_thai_giao_dich_doi_tac,
        is_transfer: values.is_transfer,
        trang_thai_truy_thu_hoan_theo_goi: values.trang_thai_truy_thu_hoan_theo_goi,
        trang_thai_truy_thu_hoan_them: values.trang_thai_truy_thu_hoan_them,
        don_hoan_hoac_nap: values.don_hoan_hoac_nap,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(RequestrefundAction.updateFilter(payload));
      const response = await dispatch(RequestrefundAction.list());
      console.log(response)

      const data = [
        [
          '#',
          'Username nhân viên',
          'Tên nhân viên',
          'Số điện thoại',
          'Loại thuê bao',
          'Gói cước',
          'Trạng thái',
          'Mã trạng thái',
          'Yêu cầu - Hình thức',
          'Số tiền HOÀN hoặc NẠP',
          'Số tiền hoàn thêm',
          'Số tiền sau chiết khấu',
          'Tỉ lệ chiết khấu (%)',
          'Thời gian yêu cầu',
          'Trạng thái chuyển khoản',
          'Nhân viên duyệt nạp tiền'
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let yeuCau = "";
          let tiLeChietKhau = "";
          let trangThaiTruyThuHoanTheoGoi = "";
          let trangThaiTruyThuHoanThem = "";

          if (element.request_type == 1) yeuCau = "Hoàn trước";
          else if (element.request_type == 2) yeuCau = "Hoàn sau";
          else if (element.request_type == 3) yeuCau = "Không hoàn";
          else if (element.request_type == 4) yeuCau = "Hoàn thêm";
          else if (element.request_type == 5) yeuCau = "Nạp tiền";

          if (element.request_method == 1) yeuCau += " - Gia hạn";
          else if (element.request_method == 2) yeuCau += " - Nâng cấp T1";
          else if (element.request_method == 3) yeuCau += " - Nâng cấp T2";
          else if (element.request_method == 4) yeuCau += " - Nâng cấp T3";

          if (element.trang_thai_truy_thu_hoan_theo_goi == 'binh_thuong') trangThaiTruyThuHoanTheoGoi = "Bình thường";
          else if (element.trang_thai_truy_thu_hoan_theo_goi == 'da_truy_thu') trangThaiTruyThuHoanTheoGoi = "Đã truy thu";
          else if (element.trang_thai_truy_thu_hoan_theo_goi == 'truy_thu') trangThaiTruyThuHoanTheoGoi = "Truy thu";

          if (element.trang_thai_truy_thu_hoan_them == 'binh_thuong') trangThaiTruyThuHoanThem = "Bình thường";
          else if (element.trang_thai_truy_thu_hoan_them == 'da_truy_thu') trangThaiTruyThuHoanThem = "Đã truy thu";
          else if (element.trang_thai_truy_thu_hoan_them == 'truy_thu') trangThaiTruyThuHoanThem = "Truy thu";

          let tiLe = '';
          let sauChietKhau = '';

          if (element.request_type == 5) {
            sauChietKhau = element.transfer_final_amount;
            tiLe = element.ti_le_chiet_khau != 0 ? element.ti_le_chiet_khau : (element.refund_price != 0 ? parseInt((element.refund_price - element.transfer_final_amount) / element.refund_price * 100) : 0)
          } else if (role.priority < 3) {
            sauChietKhau = element.tien_hoan_sau_chiet_khau;
            tiLe = element.ti_le_chiet_khau_hoan_tien != 0 ? element.ti_le_chiet_khau_hoan_tien : 0;
          }

          data.push([
            count,
            element.staff_username,
            element.staff_fullname,
            role.priority < 3 ? element.number_phone.slice(-9) : ('****' + element.number_phone.slice(-5)),
            element.number_type == 1 ? "MC" : "MG",
            element.package_code,
            element.trang_thai_giao_dich_doi_tac,
            element.ma_trang_thai_giao_dich_doi_tac,
            yeuCau,
            element.refund_price,
            element.other_refund,
            sauChietKhau,
            tiLe,
            moment(element.created_time).format('DD-MM-YYYY HH:mm:ss'),
            element.request_type == 5 ? (element.is_transfer == 1 ? 'Đã chuyển khoản' : "Chưa chuyển khoản") : '',
            element.request_type == 5 ? element.approver : '',
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'YCHT.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
    }
  }

  return (
    <Box {...props} style={{ width: "100%" }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {isGrantPermission(permissions, ["requestrefund.doi-kenh-hoan-tien"]) && <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpenSelectChannel}
        >
          Cài đặt
        </Button>}
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginLeft: 20 }}
        >
          Hoàn tiền gói cước
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen1}
          style={{ marginLeft: 20 }}
        >
          Nạp tiền chiết khấu
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <PhoneIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    name="message"
                    onChange={handleMessageChange}
                    onKeyDown={handleKeyDown}
                    value={values.message}
                    placeholder="Tìm SĐT"
                    variant="outlined"
                    style={{ height: 56, marginLeft: 0 }}
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <DatePicker
                    label="Thời gian bắt đầu"
                    style={{ width: '200px' }}
                    format='DD/MM/YYYY'
                    value={values.startDate}
                    onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <DatePicker
                    label="Thời gian kết thúc"
                    style={{ width: '200px' }}
                    value={values.endDate}
                    format='DD/MM/YYYY'
                    onChange={(newValue) => handleEndDateChange(newValue)}
                  />
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <Select
                    fullWidth
                    style={{ height: 56, marginTop: 6, marginLeft: 0 }}
                    name="trang_thai_giao_dich_doi_tac"
                    value={values.trang_thai_giao_dich_doi_tac || -99}
                    onChange={(newValue) => handleTrangThaiGiaoDichDoiTacChange(newValue)}
                    variant="outlined"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <TagIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )}
                  >
                    <MenuItem value="-99">Trạng thái đơn...</MenuItem>
                    <MenuItem value="hoan_thanh">Hoàn thành</MenuItem>
                    <MenuItem value="hoan_tien">Hoàn tiền</MenuItem>
                    <MenuItem value="da_tao_don_khong_qua_api">Đã tạo đơn không qua API</MenuItem>
                    <MenuItem value="da_nhan_cho_xu_ly">Đã nhận, chờ xử lý</MenuItem>
                    <MenuItem value="dang_cho_duyet">Đang chờ duyệt</MenuItem>
                    <MenuItem value="dang_xu_ly">Đang xử lý</MenuItem>
                  </Select>
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <Select
                    fullWidth
                    style={{ height: 56, marginTop: 6, marginLeft: 0 }}
                    name="is_transfer"
                    value={values.is_transfer || 0}
                    onChange={(newValue) => handleIsTransferChange(newValue)}
                    variant="outlined"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <CreditCard />
                        </SvgIcon>
                      </InputAdornment>
                    )}
                  >
                    <MenuItem value="0">Trạng thái CK...</MenuItem>
                    <MenuItem value="1">Chưa CK</MenuItem>
                    <MenuItem value="2">Đã CK</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              {role.priority != 5 && <Button
                color="primary"
                variant="contained"
                onClick={xuatFile}
                style={{ height: 56, marginTop: 6, marginLeft: 0 }}
                startIcon={<FileTextIcon size={15} />}
              >
                Xuất file
              </Button>}
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, marginLeft: 5 }}
                startIcon={<SearchIcon size={15} />}
              >
                Tra cứu
              </Button>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialogSelectChannel open={openSelectChannel} handleClose={handleCloseSelectChannel} type={"SELECT_CHANNEL"} />
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
      <CreateEditDialog1 open={open1} handleClose={handleClose1} type={"CREATE1"} />
    </Box>
  )
};

export default RequestrefundToolbar;
