import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const OtptracuuService = {

  create: (payload) => {
    return axios.post('/api/v1/otptracuu', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/otptracuu/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/otptracuu/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload, filterPayload) => {
    let url = `/api/v1/otptracuu?page=${page}&count=${count}`;

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (filterPayload.loai_tai_khoan) {
      url += `&loai_tai_khoan=${filterPayload.loai_tai_khoan}`
    };

    // if (payload.startDate && payload.endDate) {
    //   url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    // }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/otptracuu/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/otptracuu/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/otptracuu/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/otptracuu/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/otptracuu/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  loginAppMobileSmcs: (payload) => {
    return axios.post(`/api/v1/otptracuu/loginAppMobileSmcs`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  confirmAppMobileSmcs: (payload) => {
    return axios.post(`/api/v1/otptracuu/confirmAppMobileSmcs`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  loginMobile: (payload) => {
    return axios.post(`/api/v1/otptracuu/loginMobile`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  confirmMobile: (payload) => {
    return axios.post(`/api/v1/otptracuu/confirmMobile`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  loginTraCuuLuuLuong: (payload) => {
    return axios.post(`/api/v1/otptracuu/loginTraCuuLuuLuong`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  confirmTraCuuLuuLuong: (payload) => {
    return axios.post(`/api/v1/otptracuu/confirmTraCuuLuuLuong`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  loginDigishop: (payload) => {
    return axios.post(`/api/v1/otptracuu/loginDigishop`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  confirmDigishop: (payload) => {
    return axios.post(`/api/v1/otptracuu/confirmDigishop`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  login: (payload) => {
    return axios.post(`/api/v1/otptracuu/login`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  confirm: (payload) => {
    return axios.post(`/api/v1/otptracuu/confirm`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  resetSession: (payload) => {
    return axios.post(`/api/v1/otptracuu/resetSession`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  setStatus: (payload) => {
    return axios.post(`/api/v1/otptracuu/setStatus`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  checkTraCuu: (payload) => {
    return axios.post(`/api/v1/otptracuu/checkTraCuu`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuThongTinChiTietThueBao: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuThongTinChiTietThueBao`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuThongTinChiTietThueBao1: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuThongTinChiTietThueBao1`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuThongTinChiTietThueBao2: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuThongTinChiTietThueBao2`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuThongTinChiTietThueBao3: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuThongTinChiTietThueBao3`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuTienNapTrongNgay: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuTienNapTrongNgay`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuGoiCuocCoTheDangKy: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuGoiCuocCoTheDangKy`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuLichSuNapThe: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuLichSuNapThe`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuGoiCuocCoTheDangKy1: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuGoiCuocCoTheDangKy1`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuNgayHetHanGoiCuocHienTai: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuNgayHetHanGoiCuocHienTai`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuLichSuGoiCuoc: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuLichSuGoiCuoc`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuChuKyGoiCuocHienTai: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuChuKyGoiCuocHienTai`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuChuKyGoiCuocHienTaiTuData: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuChuKyGoiCuocHienTaiTuData`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuuLuuLuongSuDung: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuuLuuLuongSuDung`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  traCuu1543: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuu1543`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
  
  traCuu1543DB: (payload) => {
    return axios.post(`/api/v1/otptracuu/traCuu1543DB`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  deleteAccount: (id) => {
    return axios.post(`/api/v1/otptracuu/delete`, {id}, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}