import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import Budget from '../components/dashboard/Budget';
import LatestOrders from '../components/dashboard/LatestOrders';
import LatestProducts from '../components/dashboard/LatestProducts';
import Sales from '../components/dashboard/Sales';
import TasksProgress from '../components/dashboard/TasksProgress';
import TotalCustomers from '../components/dashboard/TotalCustomers';
import TotalProfit from '../components/dashboard/TotalProfit';
import TrafficByDevice from '../components/dashboard/TrafficByDevice';

const Dashboard = () => {
  const [isSheetLoaded, setIsSheetLoaded] = useState(false)

  const sheetOnLoad = event => {
    setTimeout(() => setIsSheetLoaded(true), 2e3)
  }

  return <>
    <Helmet>
      <title>Bảng điều khiển</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        {!isSheetLoaded && "Đang tải trang tính..."}
        <iframe onLoad={sheetOnLoad} frameBorder="0" style={{ width: "100%", height: "70vh" }} src="https://1drv.ms/x/c/7e37be6d70233904/IQPbzOaGV6qpSplcpvi3NI6YAQKfsKb9BqjkrpJzwtT6TR0?wdHideGridlines=True&wdHideHeaders=True&wdInConfigurator=True&wdInConfigurator=True"></iframe>


        {/* <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalCustomers />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit sx={{ height: '100%' }} />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <Sales />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <TrafficByDevice sx={{ height: '100%' }} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts sx={{ height: '100%' }} />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders />
        </Grid>
      </Grid> */}
      </Container>
    </Box>
  </>
};

export default Dashboard;
