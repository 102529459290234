import { Navigate } from 'react-router-dom';

import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import AccountList from './pages/AccountList';
import ApprovalRefund from './pages/ApprovalList';
import BankLookup from './pages/BankLookup';
import CustomerCareHistoryList from './pages/CustomerCareHistoryList';
import CustomerCareList from './pages/CustomerCareList';
import CustomerList from './pages/CustomerList';
import CustomerLookup from './pages/CustomerLookup';
import Dashboard from './pages/Dashboard';
import Division from './pages/Division';
import Giahoantien from './pages/Giahoantien';
import Invitation from './pages/Invitation';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PermissionList from './pages/PermissionList';
import Register from './pages/Register';
import ReportchitietList from './pages/ReportchitietList';
import Requestrefundreport from './pages/Requestrefundreport';
import ReportstaffrevenueList from './pages/ReportstaffrevenueList';
import ReportbranchrevenueList from './pages/ReportbranchrevenueList';
import DsdttinhluongList from './pages/DsdttinhluongList';
import TinhluongList from './pages/TinhluongList';
import Requestrefund from './pages/Requestrefund';
import RoleList from './pages/RoleList';
import Settings from './pages/Settings';
import Systemaccount from './pages/Systemaccount';
import Quetdata from './pages/Quetdata';
import UploadList from './pages/UploadList';
import Uploadchiadata from './pages/Uploadchiadata';
import Phanquyendata from './pages/Phanquyendata';
import Chiadata from './pages/Chiadata';
import Chamsoc from './pages/Chamsoc';
import Daily from './pages/Daily';
import OtptracuuLookup from './pages/OtptracuuLookup';
import Blacklist from './pages/Blacklist';
import Baodo from './pages/Baodo';
import Khr from './pages/Khr';
import VinareportLookup from './pages/VinareportLookup';
import TilebatsoList from './pages/TilebatsoList';
import Moigoihomsau from './pages/Moigoihomsau';
import LstracuuLookup from './pages/LstracuuLookup';
import _HomePage from './pages/_HomePage';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'home', element: <_HomePage /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'care-history', element: <CustomerCareHistoryList /> },
      { path: 'customer-care', element: <CustomerCareList /> },
      { path: 'customer-lookup', element: <CustomerLookup /> },
      { path: 'bank-lookup', element: <BankLookup /> },
      { path: 'invite', element: <Invitation /> },
      { path: 'request-sms', element: <Account /> },
      { path: 'approval', element: <ApprovalRefund /> },
      { path: 'requestrefund', element: <Requestrefund /> },
      { path: 'reportchitiet', element: <ReportchitietList /> },
      { path: 'requestrefundreport', element: <Requestrefundreport /> },
      { path: 'reportstaffrevenue', element: <ReportstaffrevenueList /> },
      { path: 'reportbranchrevenue', element: <ReportbranchrevenueList /> },
      { path: 'dsdttinhluong', element: <DsdttinhluongList /> },
      { path: 'tinhluong', element: <TinhluongList /> },
      { path: 'chamsoc', element: <Chamsoc /> },
      { path: 'khr', element: <Khr /> },
      { path: 'blacklist', element: <Blacklist /> },
      { path: 'baodo', element: <Baodo /> },
      { path: 'vinareport', element: <VinareportLookup /> },
      { path: 'tilebatso', element: <TilebatsoList /> },
      { path: 'moigoihomsau', element: <Moigoihomsau /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'sys',
    element: <DashboardLayout />,
    children: [
      { path: 'lstracuu', element: <LstracuuLookup /> },
      { path: 'profile', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'permissions', element: <PermissionList /> },
      { path: 'roles', element: <RoleList /> },
      { path: 'accounts', element: <AccountList /> },
      { path: 'upload', element: <UploadList /> },
      { path: 'division', element: <Division /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: 'giahoantien', element: <Giahoantien /> },
      { path: 'systemaccount', element: <Systemaccount /> },
      { path: 'quetdata', element: <Quetdata /> },
      { path: 'uploadchiadata', element: <Uploadchiadata /> },
      { path: 'phanquyendata', element: <Phanquyendata /> },
      { path: 'chiadata', element: <Chiadata /> },
      { path: 'daily', element: <Daily /> },
      { path: 'otptracuu', element: <OtptracuuLookup /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/home" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
