import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { AccountAction } from 'src/actions/account.acction';
import { ProfileAction } from 'src/actions/profile.action';
import { RoleAction } from 'src/actions/role.acction';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';

const AccountEditDialog = (props) => {
    const dispatch = useDispatch()
    const { items } = useSelector(state => state.role)
    const { leaders, brands } = useSelector(state => state.profile)
    const { selected } = useSelector(state => state.account)
    const [showLeader, setShowLeader] = useState(false);
    const [showBrand, setShowBrand] = useState(false);
    const [selectedRole, setSelectedRole] = useState(undefined)

    useEffect(() => {
        dispatch(RoleAction.list(0, 100))
        dispatch(ProfileAction.listBrand())
    }, [])

    const [values, setValues] = useState({
        fullname: "",
        email: "",
        phone: "",
        brand: "",
        groud: "",
        role: "",
        sipAccount: "",
        sipPassword: ""
    });

    useEffect(() => {
        if (selected) {
            console.log(selected);
            setValues({
                fullname: selected.fullname,
                email: selected.email || "",
                phone: selected.phone || "",
                brand: selected.brand_id ? selected.brand_id : "",
                groud: selected.group_id ? selected.group_id : "",
                role: selected.role_id,
                sip_account: selected.sip_account,
                sip_password: selected.sip_password
            });
            setSelectedRole(selected.role)
            if (selected.brand) {
                dispatch(ProfileAction.listLeader(selected.brand_id))
                setShowBrand(true)
            }

            if (selected.groud) {
                setShowLeader(true)
            }
        } else {
            props.handleClose()
        }
    }, [selected])

    useEffect(() => {
        if (leaders && leaders.length > 0 && selectedRole && selectedRole.priority === 5) {
            setShowLeader(true)
        }

        if (selected && selected.group) {
            setValues({
                ...values,
                groud: selected.group_id,
            });
        }
    }, [leaders])

    useEffect(() => {
        if (items && items.length > 0) {
            setValues({
                ...values,
                role: items[0].id
            });
        }
    }, [items])

    useEffect(() => {
        if (brands && brands.length > 0 && selectedRole && selectedRole.priority >= 4) {
            setShowBrand(true)
        }
    }, [brands])

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        if (event.target.name === 'role') {
            let _selected = items.find(r => r.id === event.target.value);
            setSelectedRole(_selected)
            // Leader
            if (_selected.priority >= 4) {
                dispatch(ProfileAction.listBrand())
            } else {
                setShowBrand(false)
                setShowLeader(false)
            }
            if (_selected.priority === 5) {
                if (values.brand) {
                    dispatch(ProfileAction.listLeader(values.brand))
                }
            }
        }

        if (event.target.name === 'brand' && selectedRole.priority === 5) {
            dispatch(ProfileAction.listLeader(event.target.value))
        }


    };

    const resetData = () => {
        setValues({
            fullname: '',
            username: '',
            password: '',
            email: '',
            phone: '',
            brand: '',
            groud: '',
            role: '',
            sip_account: '',
            sip_password: ''
        })
        setShowBrand(false)
        setShowLeader(false)
        setSelectedRole(undefined)
    }

    const handleUpdate = () => {
        if (items.length > 0 && values.role === '') {
            values.role = items[0].id
        }
        dispatch(AccountAction.update(selected.id, values))
        resetData()
        props.handleClose()
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cập nhật người dùng</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Họ và tên"
                            name="fullname"
                            onChange={handleChange}
                            required
                            value={values.fullname}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            required
                            value={values.email}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Số điện thoại"
                            name="phone"
                            onChange={handleChange}
                            type="number"
                            value={values.phone}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Vai trò"
                            name="role"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.role}
                            variant="outlined"
                        >
                            {items.map((option) => (
                                <option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    {showBrand === true && brands && brands.length > 0 && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chi nhánh"
                            name="brand"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.brand}
                            variant="outlined"
                        >
                            {[{ id: "00000000-0000-0000-0000-000000000000", fullname: "<<Chọn chi nhánh>>" }, ...brands].map((option) => (
                                <option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.fullname}
                                </option>
                            ))}
                        </TextField>
                    </Grid>}
                    {showLeader === true && leaders && leaders.length > 0 && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Nhóm"
                            name="group"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.leaders}
                            variant="outlined"
                        >
                            {[{ id: "00000000-0000-0000-0000-000000000000", fullname: "<<Chọn nhóm>>" }, ...leaders].map((option) => (
                                <option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.fullname}
                                </option>
                            ))}
                        </TextField>
                    </Grid>}

                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            name="sip_account"
                            label="Tài khoản SIP"
                            onChange={handleChange}
                            type="text"
                            value={values.sip_account}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            name="sip_password"
                            label="Mật khẩu SIP"
                            onChange={handleChange}
                            type="text"
                            value={values.sip_password}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={handleUpdate}>Cập nhật</Button>
            </DialogActions>
        </Dialog >
    );
}

export default AccountEditDialog;