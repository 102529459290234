import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { VinareportAction } from 'src/actions/vinareport.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import numeral from 'numeral';

const VinareportLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter } = useSelector(state => state.vinareport);

  const [selectedVinareportIds, setSelectedVinareportIds] = useState([]);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);


  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
    };

    dispatch(VinareportAction.updateFilter(payload));

    dispatch(VinareportAction.list());
  }, [page])

  const handleSelectAll = (event) => {
    let newSelectedVinareportIds;

    if (event.target.checked) {
      newSelectedVinareportIds = items.map((vinareport) => vinareport.id);
    } else {
      newSelectedVinareportIds = [];
    }

    setSelectedVinareportIds(newSelectedVinareportIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedVinareportIds.indexOf(id);
    let newSelectedVinareportIds = [];

    if (selectedIndex === -1) {
      newSelectedVinareportIds = newSelectedVinareportIds.concat(selectedVinareportIds, id);
    } else if (selectedIndex === 0) {
      newSelectedVinareportIds = newSelectedVinareportIds.concat(selectedVinareportIds.slice(1));
    } else if (selectedIndex === selectedVinareportIds.length - 1) {
      newSelectedVinareportIds = newSelectedVinareportIds.concat(selectedVinareportIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedVinareportIds = newSelectedVinareportIds.concat(
        selectedVinareportIds.slice(0, selectedIndex),
        selectedVinareportIds.slice(selectedIndex + 1)
      );
    }

    setSelectedVinareportIds(newSelectedVinareportIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                {/* <TableCell>
                  Mã giao dịch
                </TableCell> */}
                <TableCell>
                  Chi nhánh đăng ký
                </TableCell>
                <TableCell>
                  Username NV
                </TableCell>
                <TableCell>
                  Tên NV
                </TableCell>
                <TableCell>
                  Thuê bao
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Giá gói
                </TableCell>
                <TableCell>
                  Ngày đăng ký
                </TableCell>
                <TableCell>
                  Kết quả giao dịch
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((vinareport, index) => (
                <TableRow
                  hover
                  key={vinareport.id}
                  selected={selectedVinareportIds.indexOf(vinareport.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  {/* <TableCell>
                    {vinareport.trans_id}
                  </TableCell> */}
                  <TableCell>
                    {vinareport.branch_name} ({vinareport.branch_code})
                  </TableCell>
                  <TableCell>
                    {vinareport.username_nv}
                  </TableCell>
                  <TableCell>
                    {vinareport.ten_nv}
                  </TableCell>
                  <TableCell>
                    {vinareport.subscriber}
                  </TableCell>
                  <TableCell>
                    {vinareport.service_code}
                  </TableCell>
                  <TableCell>
                    {numeral(vinareport.price).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {moment(vinareport.regis_date).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {vinareport.status == '1' || vinareport.status == '33' ? 'Thành công' : 'Thất bại'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default VinareportLookupDetail;
