import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ReportAction } from 'src/actions/report.acction';
import { RoleAction } from 'src/actions/role.acction';
import {
  DEFAULT_PAGE,
  DEFAULT_ROW_PER_PAGE,
  InvitationStatus,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

const ReportListResults = (props) => {

  const dispatch = useDispatch()
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { query9332, items9332, total9332, query9084, items9084, total9084 } = useSelector(state => state.report)
  const [items, setItems] = useState([])
  const [query, setQuery] = useState(undefined)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (props.type === '9084') {
      let payload = query || {
        endDate: moment(new Date()).format('DD/MM/YYYY'),
        status: 'SUCCESS',
        startDate: moment(new Date()).format('DD/MM/YYYY'),
        user: '',
        page: DEFAULT_PAGE,
        count: DEFAULT_ROW_PER_PAGE
      }
      dispatch(ReportAction.list9084({...payload, status: "SUCCESS"}))
      console.log({...payload, status: "SUCCESS"})
    }
  }, [page])

  useEffect(() => {
    if (props.type === '9332') {
      setTotal(total9332)
      setItems(items9332)
      setQuery(query9332)
    }
  }, [query9332, items9332, total9332])

  useEffect(() => {
    if (props.type === '9084') {
      setTotal(total9084)
      setItems(items9084)
      setQuery(query9084)
    }
  }, [query9084, items9084, total9084])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const onSelected = (id) => {
    dispatch(RoleAction.findOne(id));
  };


  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  #
                </TableCell>
                <TableCell>
                  •Số thuê bao<br></br>•Tên gói cước<br></br>•Giá gói cước
                </TableCell>
                <TableCell>
                  •Mã nhân viên<br></br>•Tên nhân viên
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Tin nhắn trả về
                </TableCell>
                <TableCell>
                  Thời gian
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((item, idx) => (
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell >
                    {idx + 1}
                  </TableCell>
                  <TableCell>
                    •{item.msisdn}
                    <br></br>
                    •{item.packCode}
                    <br></br>
                    •{item.packPrice}
                  </TableCell>
                  <TableCell>
                    {item.invitation != null && item.invitation.inviteBy != null && "•" + item.invitation.inviteBy.username}
                    {item.invitation != null && item.invitation.inviteBy != null && <><br></br></>}
                    {item.invitation != null && item.invitation.inviteBy != null && "•" + item.invitation.inviteBy.fullname}
                  </TableCell>
                  <TableCell>
                    {InvitationStatus.filter(stt => stt.code === item.status)[0]?.name}
                  </TableCell>
                  <TableCell width={"20%"}>
                    {item.invitation != null && item.invitation.result}
                  </TableCell>
                  <TableCell>
                    {moment(item.genDate).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </Card>
  );
};

export default ReportListResults;
