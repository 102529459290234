import { useState, useEffect } from 'react';

import { Search as SearchIcon } from 'react-feather';

import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import AccountCreateDialog from './AccountCreateDialog';
import { AccountAction } from 'src/actions/account.acction';

import moment from 'moment';
import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';
import { AppAction } from 'src/actions/app.action';

const AccountListToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [values, setValues] = useState({
    username: "",
  });

  const handleSearchChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      username: "",
    };
    dispatch(AccountAction.updateFilter(payload));
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: values.username,
    };
    dispatch(AccountAction.updateFilter(payload));
    dispatch(AccountAction.list())
  };

  const xuatFile = async () => {
    if (role.priority == 5) return;
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        username: values.username,
      };
      dispatch(AccountAction.updateFilter(payload));
      const response = await dispatch(AccountAction.list());
      console.log(response)

      const data = [
        [
          '#', 
          'Tên nhân viên', 
          'Username nhân viên', 
          'Mã mời gói',
          'Email', 
          'Vai trò', 
          'Trạng thái', 
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data && typeof response.data.values !== 'undefined' && response.data.values && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {          
          data.push([
            count,
            element.full_name,
            element.username,
            element.branch_code,
            element.email,
            element.role_name,
            !element.is_deleted ? "Đang hoạt động" : "Ngừng hoạt động",
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'BAO-CAO-DANH-SACH-NHAN-VIEN.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Tạo người dùng
        </Button>
        {role.priority != 5 && <Button
          color="primary"
          variant="contained"
          onClick={xuatFile}
          style={{ marginLeft: 15 }}
        >
          Xuất file
        </Button>}
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="username"
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                value={values.username}
                placeholder="Tìm theo username NV"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 15 }}
                >
                Tra cứu
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <AccountCreateDialog open={open} handleClose={handleClose} />
    </Box>
  )
}

export default AccountListToolbar;
