import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ReportchitietAction } from 'src/actions/reportchitiet.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from '@material-ui/core';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

const ReportchitietDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongDoanhThu, tongGoi } = useSelector(state => state.reportchitiet);
  const { role } = useSelector(state => state.profile);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [updateUserValue, setUpdateUserValue] = useState({});
  const [detailList, setDetailList] = useState([])


  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
    };

    dispatch(ReportchitietAction.updateFilter(payload));
    dispatch(ReportchitietAction.list());
  }, [page])

  useEffect(() => {
    const clone = [...items]
    setDetailList(clone)
  }, [items])

  useEffect(() => {
    console.log(detailList)
  }, [detailList])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleUserChange = (event, username, track0, track1) => {
    username = username.trim();
    if (username != '') {
      setUpdateUserValue({
        ...updateUserValue,
        [track0 + '_' + track1]: username,
      });
    }
  }

  const handleUserChangeConfirm = async (event, username, track0, track1) => {
    if (event.key === 'Enter') {
      username = username.trim();
      if (username != '') {
        handleUserChange(event, username, track0, track1)
        try {
          const result = await dispatch(ReportchitietAction.updateUser(username, track0, track1));

          if (typeof result.code !== 'undefined' && typeof result.msg !== 'undefined') {
            showToast('error', result.msg);
          } else {
            showToast('success', 'Cập nhật thành công!');
          }
        } catch (error) {
          showToast('error', 'Unknown');
        }
      }
    }
  };

  return (
    <Card {...rest} style={{ width: "100%" }}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ color: "green" }}>
                  TỔNG GÓI
                </TableCell>
                <TableCell style={{ color: "green" }}>
                  TỔNG DOANH THU
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ color: "green" }}>
                  {numeral(tongGoi).format('0,0')}
                </TableCell>
                <TableCell style={{ color: "green" }}>
                  {numeral(tongDoanhThu).format('0,0')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Kênh
                </TableCell>
                <TableCell>
                  Username NV
                </TableCell>
                <TableCell>
                  Tên NV
                </TableCell>
                <TableCell>
                  SĐT chăm sóc
                </TableCell>
                <TableCell>
                  Gói cước
                </TableCell>
                <TableCell>
                  Doanh thu
                </TableCell>
                <TableCell>
                  Ngày thành công
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailList.slice(page * limit, (page + 1) * limit).map((reportchitiet, index) => (
                <TableRow
                  hover
                  key={reportchitiet.id}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {reportchitiet.kenh}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {role.priority < 3 && <TextField
                      name={reportchitiet.track0 + '_' + reportchitiet.track1}
                      onChange={(e) => handleUserChange(e, e.target.value, reportchitiet.track0, reportchitiet.track1)}
                      onKeyDown={(e) => handleUserChangeConfirm(e, e.target.value, reportchitiet.track0, reportchitiet.track1)}
                      value={updateUserValue[reportchitiet.track0 + '_' + reportchitiet.track1] || reportchitiet.username_nv}
                      placeholder=""
                      variant="outlined"
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {role.priority > 2 && reportchitiet.username_nv}
                  </TableCell>
                  <TableCell>
                    {reportchitiet.ten_nv}
                  </TableCell>
                  <TableCell>
                    {reportchitiet.so_dien_thoai_cs}
                  </TableCell>
                  <TableCell>
                    {reportchitiet.goi_cuoc}
                  </TableCell>
                  <TableCell>
                    {numeral(reportchitiet.doanh_thu).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {moment(reportchitiet.ngay_thanh_cong).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default ReportchitietDetail;
