import { QuetdataService } from 'src/services/quetdata.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const QUETDATA_NEW_CARE_SUCCESS = 'QUETDATA:NEW_CARE_SUCCESS'
export const QUETDATA_LIST_SUCCESS = 'QUETDATA:FIND_ALL_SUCCESS'
export const QUETDATA_LIST_FAILD = 'QUETDATA:FIND_ALL_FAILD'
export const QUETDATA_COUNT_ASSIGN_SUCCESS = 'QUETDATA:COUNT_ASSIGN_SUCCESS'
export const QUETDATA_COUNT_ASSIGN_FAILD = 'QUETDATA:COUNT_ASSIGN_FAILD'

export const QUETDATA_CREATE_SUCCESS = 'QUETDATA:CREATE_SUCCESS'
export const QUETDATA_CREATE_FAILD = 'QUETDATA:CREATE_FAILD'

export const QUETDATA_IMPORT_SUCCESS = 'QUETDATA:IMPORT_SUCCESS'
export const QUETDATA_SAVE_SUCCESS = 'QUETDATA:SAVE_SUCCESS'
export const QUETDATA_SAVE_FAILD = 'QUETDATA:SAVE_SUCCESS'

export const QUETDATA_DEL_SUCCESS = 'QUETDATA:DEL_SUCCESS'
export const QUETDATA_DEL_FAILD = 'QUETDATA:DEL_FAILD'

export const QUETDATA_ONE_SUCCESS = 'QUETDATA:FIND_ONE_SUCCESS'
export const QUETDATA_ONE_FAILD = 'QUETDATA:FIND_ONE_FAILD'

export const QUETDATA_HISTORY_LIST_SUCCESS = 'QUETDATA:HISTORY_FIND_ALL_SUCCESS'
export const QUETDATA_HISTORY_LIST_FAILD = 'QUETDATA:HISTORY_FIND_ALL_FAILD'

export const QUETDATA_LIST_BY_FILENAME_SUCCESS = 'QUETDATA:FIND_ALL_BY_FILENAME_SUCCESS'
export const QUETDATA_LIST_BY_FILENAME_FAILD = 'QUETDATA:FIND_ALL_BY_FILENAME_FAILD'

export const QuetdataAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    saveDataUpload,
    findAllByFileName,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdataService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { quetdata } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdataService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdata.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_LIST_FAILD, error } }
}

function list(page = 0, count = 20,) {
    return (dispatch, getState) => {
        const { quetdata } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdataService.findAll(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdata.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_LIST_FAILD, error } }
}

function findAllByFileName(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            QuetdataService.findAllByFileName(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: QUETDATA_LIST_BY_FILENAME_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_LIST_BY_FILENAME_FAILD, error } }
}

function importData(payload) {
    return { type: QUETDATA_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuetdataService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(QuetdataAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUETDATA_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_SAVE_FAILD, error } }
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        QuetdataService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdataAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_CREATE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        QuetdataService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdataAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_CREATE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        QuetdataService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(QuetdataAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_DEL_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        QuetdataService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_ONE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_ONE_FAILD, error } }
}

function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        QuetdataService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(QuetdataAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function history(payload) {
    return (dispatch, getState) => {
        const { quetdata } = getState()
        dispatch(AppAction.appLoading(true));
        QuetdataService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...quetdata.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: QUETDATA_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_HISTORY_LIST_FAILD, error } }
}

function saveDataUpload(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            QuetdataService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: QUETDATA_SAVE_SUCCESS, data } }
    function failure(error) { return { type: QUETDATA_SAVE_FAILD, error } }
}