import { DailyService } from 'src/services/daily.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const DAILY_UPDATE_FILTER = 'DAILY:DAILY_UPDATE_FILTER'

export const DAILY_NEW_CARE_SUCCESS = 'DAILY:NEW_CARE_SUCCESS'
export const DAILY_LIST_SUCCESS = 'DAILY:FIND_ALL_SUCCESS'
export const DAILY_LIST_FAILD = 'DAILY:FIND_ALL_FAILD'
export const DAILY_COUNT_ASSIGN_SUCCESS = 'DAILY:COUNT_ASSIGN_SUCCESS'
export const DAILY_COUNT_ASSIGN_FAILD = 'DAILY:COUNT_ASSIGN_FAILD'

export const DAILY_CREATE_SUCCESS = 'DAILY:CREATE_SUCCESS'
export const DAILY_CREATE_FAILD = 'DAILY:CREATE_FAILD'

export const DAILY_IMPORT_SUCCESS = 'DAILY:IMPORT_SUCCESS'
export const DAILY_SAVE_SUCCESS = 'DAILY:SAVE_SUCCESS'
export const DAILY_SAVE_FAILD = 'DAILY:SAVE_SUCCESS'

export const DAILY_DEL_SUCCESS = 'DAILY:DEL_SUCCESS'
export const DAILY_DEL_FAILD = 'DAILY:DEL_FAILD'


export const DAILY_HISTORY_LIST_SUCCESS = 'DAILY:HISTORY_FIND_ALL_SUCCESS'
export const DAILY_HISTORY_LIST_FAILD = 'DAILY:HISTORY_FIND_ALL_FAILD'

export const DAILY_ONE_SUCCESS = 'DAILY:FIND_ONE_SUCCESS'
export const DAILY_ONE_FAILD = 'DAILY:FIND_ONE_FAILD'

export const DailyAction = {
    list,
    findAssign,
    create,
    createMulti,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    capMaTuDong,
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        DailyService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_ONE_SUCCESS, data } }
    function failure(error) { return { type: DAILY_ONE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        DailyService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: DAILY_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 10) {
    return (dispatch, getState) => {
        const { daily } = getState()
        dispatch(AppAction.appLoading(true));
        DailyService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...daily.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_LIST_SUCCESS, data } }
    function failure(error) { return { type: DAILY_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { daily } = getState();
        const { filter } = daily;
        const page = filter.page;
        const limit = filter.limit;
        DailyService.findAll(page, limit, filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = daily.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }

                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_LIST_SUCCESS, data } }
    function failure(error) { return { type: DAILY_LIST_FAILD, error } }
}

function updateFilter(payload) {
    return { type: DAILY_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: DAILY_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            DailyService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(DailyAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: DAILY_SAVE_SUCCESS, data } }
    function failure(error) { return { type: DAILY_SAVE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        DailyService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(DailyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DAILY_CREATE_FAILD, error } }
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        DailyService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(DailyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DAILY_CREATE_FAILD, error } }
}

function capMaTuDong() {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        DailyService.capMaTuDong().then((rs) => {
            dispatch(success(rs));
            dispatch(DailyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DAILY_CREATE_FAILD, error } }
}

function createMulti(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        DailyService.createMulti(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(DailyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_CREATE_SUCCESS, data } }
    function failure(error) { return { type: DAILY_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        DailyService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(DailyAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_DEL_SUCCESS, data } }
    function failure(error) { return { type: DAILY_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        DailyService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(DailyAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { daily } = getState()
        dispatch(AppAction.appLoading(true));
        DailyService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...daily.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: DAILY_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: DAILY_HISTORY_LIST_FAILD, error } }
}