import { Helmet } from 'react-helmet';
import CustomerLookupBalance
  from 'src/components/customer-lookup/CustomerLookupBalance';
import CustomerLookupInfo
  from 'src/components/customer-lookup/CustomerLookupInfo';
import CustomerLookupInfo1
  from 'src/components/customer-lookup/CustomerLookupInfo1';
import CustomerLookupInfo2
  from 'src/components/customer-lookup/CustomerLookupInfo2';
import CustomerLookupInfo3
  from 'src/components/customer-lookup/CustomerLookupInfo3';
import CustomerLookupPackageHistory
  from 'src/components/customer-lookup/CustomerLookupPackageHistory';
import CustomerLookupPackageHistory1
  from 'src/components/customer-lookup/CustomerLookupPackageHistory1';
import CustomerLookup1543
  from 'src/components/customer-lookup/CustomerLookup1543';

import {
  Box,
  Container,
} from '@material-ui/core';
import CustomerLookupPackageHistory2 from 'src/components/customer-lookup/CustomerLookupPackageHistory2';
import CustomerLookupLuuLuongSuDung from 'src/components/customer-lookup/CustomerLookupLuuLuongSuDung';

import { isGrantPermission } from 'src/utils/utils';
import CustomerLookupInfo4 from 'src/components/customer-lookup/CustomerLookupInfo4';

const CustomerLookup = () => (
  <>
    <Helmet>
      <title>Tra cứu thông tin thuê bao</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupLuuLuongSuDung />
        </Box>
        {/* <CustomerLookupBalance /> */}
        <Box sx={{ pt: 3 }}>
          <CustomerLookupInfo />
        </Box>
        {/* <Box sx={{ pt: 3 }}>
          <CustomerLookupInfo1 />
        </Box> */}
        <Box sx={{ pt: 3 }}>
          <CustomerLookupInfo2 />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupInfo3 />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupInfo4 />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupPackageHistory />
        </Box>
        {/* <Box sx={{ pt: 3 }}>
          <CustomerLookupPackageHistory1 />
        </Box> */}
        <Box sx={{ pt: 3 }}>
          <CustomerLookupPackageHistory2 />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookup1543 />
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerLookup;
