import React, { useEffect, useState } from 'react';

import { Search as SearchIcon, Download as DownloadIcon, PhoneCall, UserPlus } from 'react-feather';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import { isUserHasPermission } from 'src/utils/common';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import CustomerCareCreateDialog from './CustomerCareCreateDialog';
import CustomerCareNewInfo from './CustomerCareNewInfo';

const CustomerCareListToolbar = (props) => {
  const { newCare } = useSelector(state => state.customer)
  const { accessToken } = useSelector(state => state.auth)
  const { info, permissions, role } = useSelector(state => state.profile)

  const dispatch = useDispatch()

  const handleNewCarePress = () => {
    dispatch(CustomerAction.newCare())
  }

  const [openResult, setOpenResult] = useState(false)
  const [isCaring, setIsCaring] = useState(false)
  const [isCallServiceReady, setIsCallServiceReady] = useState(null)
  const [callWindow, setCallWindow] = useState(null)

  useEffect(() => {
    if (isUserHasPermission(permissions, "sip.call")) {
      const socket = new WebSocket("ws://127.0.0.1:3000/test")

      socket.onopen = () => {
        setIsCallServiceReady(true)
      }

      socket.onerror = () => {
        setIsCallServiceReady(false)
      }
    }
  }, [])

  const handleCarePress = () => {
    setOpenResult(true);
    setIsCaring(true);
  };

  const handleAlertPress = () => {

  }
  const handleClose = () => {
    setOpenResult(false);
    setIsCaring(false);
  };

  const handleDownloadCallApp = () => {
    var timeout = null

    const blurHandler = function () {
      window.clearTimeout(timeout)
      window.removeEventListener('blur', blurHandler)
    }

    const timeoutHandler = function () {
      window.removeEventListener('blur', blurHandler)
      window.location.href = "https://cskh.dvvinaphone.vn/services/call-platform/CallAppLoader.exe"
    }

    window.addEventListener('blur', blurHandler)
    timeout = window.setTimeout(timeoutHandler, 100)

    window.location.href = "callapp://"
  }

  const handleCallCustomNumber = () => {
    const pn = prompt("Nhập số điện thoại: ")

    if (pn == null) {
      return
    }

    if (callWindow != null) {
      callWindow.close()
    }

    let _callWindow = window.open("https://cskh.dvvinaphone.vn/services/call-platform/index.html", "_blank", "width=600,height=500")
    setCallWindow(_callWindow)

    if (_callWindow) {
      const access_token = accessToken

      window.addEventListener("mounted", () => {
        _callWindow.dispatchEvent(new CustomEvent("run", { detail: { access_token, phone: pn } }))
      })

      _callWindow.onbeforeunload = () => {
        setCallWindow(null)
      }

      var interval = setInterval(function () {
        try {
          if (window == null || window.closed) {
            setCallWindow(null)
            clearInterval(interval)
          }
        } catch (e) { }
      }, 100)
    } else {
      alert("The browser blocked opening the new window. Please check your browser settings.");
    }
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {((isCallServiceReady != null && !isCallServiceReady) && isUserHasPermission(permissions, "sip.call")) && <Button
          style={{ marginRight: 10 }}
          color="info"
          variant="contained"
          startIcon={<DownloadIcon size={15} />}
          onClick={handleDownloadCallApp}
        >
          Tải xuống ứng dụng gọi
        </Button>}

        {(isCallServiceReady && isUserHasPermission(permissions, "sip.call")) && <Button
          style={{ marginRight: 10 }}
          color="info"
          variant="contained"
          startIcon={<PhoneCall size={15} />}
          onClick={handleCallCustomNumber}
        >
          Gọi khách hàng riêng
        </Button>}

        <Button
          color="primary"
          disabled={isCaring || newCare ? true : false}
          variant="contained"
          onClick={handleNewCarePress}
          startIcon={<UserPlus size={15} />}
        >
          Chăm sóc khách hàng mới
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <CustomerCareNewInfo onCare={handleCarePress} onAlert={handleAlertPress} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CustomerCareCreateDialog open={openResult} handleClose={handleClose} customer={newCare} />
    </Box>
  )
};

export default CustomerCareListToolbar;
