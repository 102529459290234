import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const TinhluongService = {
  saveFileChamCong: (payload) => {
    return axios.put('/api/v1/tinhluong/saveFileChamCong', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  saveFileDuLieuNhapTay: (payload) => {
    return axios.put('/api/v1/tinhluong/saveFileDuLieuNhapTay', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findTinhLuong: (payload) => {
    return axios.post(`/api/v1/tinhluong/findTinhLuong`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  updateTinhLuong: (payload) => {
    return axios.put(`/api/v1/tinhluong/updateTinhLuong`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  create: (payload) => {
    return axios.post('/api/v1/tinhluong', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/tinhluong/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/tinhluong/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateUser: (username, track0, track1) => {
    return axios.post(`/api/v1/tinhluong/updateUser`, { username, track0, track1 }, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/tinhluong?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (payload.startDate) {
      url += `&startDate=${payload.startDate}`
    }

    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  listExport: (payload) => {
    let url = `/api/v1/tinhluong/listExport?page=${payload.page}&count=${payload.limit}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.message) {
      url += `&message=${payload.message}`
    };

    if (payload.startDate) {
      url += `&startDate=${payload.startDate}`
    }

    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/tinhluong/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/tinhluong/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/tinhluong/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/tinhluong/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/tinhluong/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  getConditions: (payload) => {
    return axios.get(`/api/v1/tinhluong/getProfitConditions?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  saveConditions: (payload) => {
    return axios.post(`/api/v1/tinhluong/saveProftCondtions`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}