import { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import InvitaionImmediate from 'src/components/invitation/InvitaionImmediate';
import InvitaionRenew from 'src/components/invitation/InvitaionRenew';
import InvitaionSelling from 'src/components/invitation/InvitaionSelling';
import InvitationOTP from 'src/components/invitation/InvitationOTP';
import InvitationOTPnew from 'src/components/invitation/InvitationOTPnew';
import ReportListResults from '../components/report/ReportListResults';
import ReportListResultsnew from '../components/report/ReportListResultsnew';
import ReportListResults1 from '../components/report/ReportListResults1';

import {
  Box,
  Container,
  Divider,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   backgroundColor: theme.palette.background.paper,
  //   width: '100%',
  // },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Invitation = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { permissions } = useSelector(state => state.profile)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Mời gói cước</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={true}>
          <Paper className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {permissions && permissions.filter((p) => p.code.includes('care.')).map((per, idx) => {
                if (per.code === 'care.otp') {
                  return (
                    <Tab label="Mã xác nhận 1" style={{ flex: 1, height: 72, display: 'none' }} />
                  )
                }

                if (per.code === 'care.otpnew') {
                  return (
                    <Tab label="Mã xác nhận 2" style={{ flex: 1, height: 72, display: 'none' }} />
                  )
                }

                if (per.code === 'care.9332') {
                  return (
                    <Tab label="Mời gói cước 1543" style={{ flex: 1, height: 72 }} />
                  )
                }

                if (per.code === 'care.selling') {
                  return (
                    <Tab label="Bán gói cước" style={{ flex: 1, height: 72, display: 'none' }} />
                  )
                }

                if (per.code === 'care.dk') {
                  return (
                    <Tab label="Đăng ký trực tiếp" style={{ flex: 1, height: 72, display: 'none' }} />
                  )
                }
                return null
              })}
            </Tabs>
            <Divider />
            {permissions && permissions.filter((p) => p.code.includes('care.')).map((per, idx) => {

              if (per.code === 'care.otp') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: 'none' }}>
                    <InvitationOTP />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResults type='9084' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.otpnew') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: 'none' }}>
                    <InvitationOTPnew />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResultsnew type='9084new' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.9332') {
                return (
                  <TabPanel value={value} index={idx}>
                    <InvitaionRenew />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResults1 type='9332' />
                    </Box>
                  </TabPanel>
                )
              }

              if (per.code === 'care.selling') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: 'none' }}>
                    <InvitaionSelling />
                  </TabPanel>
                )
              }

              if (per.code === 'care.dk') {
                return (
                  <TabPanel value={value} index={idx} style={{ display: 'none' }}>
                    <InvitaionImmediate />
                    <Box sx={{ pt: 3 }}>
                      <ReportListResults1 type='9332' />
                    </Box>
                  </TabPanel>
                )
              }
              return null
            })}
          </Paper>
        </Container>
      </Box>
    </>
  )
};

export default Invitation;
