import {
  INVITE_CONFIRM_OTP_FAILD,
  INVITE_CONFIRM_OTP_SUCCESS,
  INVITE_GET_OTP_SUCCESS,
  INVITE_CONFIRM_OTPnew_FAILD,
  INVITE_CONFIRM_OTPnew_SUCCESS,
  INVITE_GET_OTPnew_SUCCESS,
} from 'src/actions/invite.action';

const initState = {
  getOtp: undefined,
  confirmOtp: undefined
}

const inviteReducer = (state = initState, action) => {
  switch (action.type) {
    case INVITE_GET_OTP_SUCCESS: {
      return {
        ...state,
        getOtp: action.data,
      }
    }
    case INVITE_CONFIRM_OTP_SUCCESS: {
      return {
        ...state,
        getOtp: undefined,
        confirmOtp: action.data
      }
    }
    case INVITE_CONFIRM_OTP_FAILD: {
      return {
        ...state,
        getOtp: undefined,
        confirmOtp: action.data
      }
    }
    case INVITE_GET_OTPnew_SUCCESS: {
      return {
        ...state,
        getOtp: action.data,
      }
    }
    case INVITE_CONFIRM_OTPnew_SUCCESS: {
      return {
        ...state,
        getOtp: undefined,
        confirmOtp: action.data
      }
    }
    case INVITE_CONFIRM_OTPnew_FAILD: {
      return {
        ...state,
        getOtp: undefined,
        confirmOtp: action.data
      }
    }
    default: {
      return state;
    }
  }
}
export default inviteReducer