import { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { CustomerTypes } from 'src/utils/constants';

import io from 'socket.io-client';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  TableRow,
  TableCell,
  Typography,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { LookupAction } from 'src/actions/lookup.action';
import { OtptracuuAction } from 'src/actions/otptracuu.action';

import { showToast } from 'src/utils/common';

import { isGrantPermission } from 'src/utils/utils';

// const socket = io("https://sys.dvmobifone.com", { rejectUnauthorized: false })

// /** MAIN CONFIG **/
// const VAS_CLIENT_ID = "PGeyhibEDXUFliwERbD6MbOBcF7QJb1AexX2tyhLbFPkSlg6PPUKABgK0PLZtybAzMTbOsd4f4v8cXJSEyzkoD9a14vzzjVOIIRn"
// /** =========== **/

// socket.emit("vas-client", VAS_CLIENT_ID)
// socket.on("connect", () => {
//   console.log("Đã kết nối với máy chủ VAS")
// })

// socket.on("disconnect", () => {
//   console.log("Mất kết nối với máy chủ VAS")
// })

const CustomerLookupInfo3 = (props) => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({});
  const [data, setData] = useState([]);
  const [cusInfo, setCusInfo] = useState({});
  const [searchPhone, setSearchPhone] = useState({phone: ""});
  const [clickTraCuu, setClickTraCuu] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = async () => {
    var phone = (values.phone).substring((values.phone).length - 9)

    // setSearchPhone({phone: phone})
    // socket.emit("traCuuThongTinChiTietThueBaoVina12qGR4fxFjBJEFY4QtMbG2wxdk89lYHKYQNv6l8tAbkG66nB42dcSXkO6wAnVr0HV2p6tAhtrbSKjJc41i2MTD5IqrhzYifkiHwv", {
    //   phone: phone,
    // })

    setClickTraCuu(true);

    try {
      const result = await dispatch(OtptracuuAction.traCuuGoiCuocCoTheDangKy1(phone));
      console.log(result);

      if (result && result.result == 'success') {
        setCusInfo(result.outputData);
        showToast('success', 'Lấy dữ liệu thành công!')
        setClickTraCuu(false);
      } else {
        showToast('error', typeof result.mes !== 'undefined' ? result.mes : 'Lấy dữ liệu thất bại!');
        setClickTraCuu(false);
      }
    } catch (e) {
      showToast('error', 'Lấy dữ liệu thất bại!');
      setClickTraCuu(false);
    }
  }

  // socket.on("traKetQuaTraCuuThongTinChiTietThueBaoVina0M93yLCUz8T8oGPy8vuBKbxlq4uDRRNzgNqxK8imkTCd2e5Eo4ugTBjCdWiUbCmnXs9TNfgN0rg6DTf183sjRjhhBslRnp4cNZRr",  async (data) => {
  //   if (data != null && typeof data.data !== 'undefined' && data.data != null && typeof data.data.GetInfo !== 'undefined' && data.data.GetInfo != null && typeof data.data.GetInfo.MSISDN !== 'undefined' && data.data.GetInfo.MSISDN != null) {
  //     const result = await data.data.GetInfo.MSISDN;
  //     const searching = await searchPhone.phone;
  //     if (result.toString().slice(-9) == searching.toString().slice(-9)) {
  //       //console.log(data.data);
  //       setCusInfo(data.data);
  //     }
  //   }
  //   setSearchPhone({phone: ''})
  // })

  return (
    <form {...props}>
      {isGrantPermission(permissions, ['tra_cuu_2_2']) && <Card>
        <CardHeader
          title="#2.2 TRA CỨU GÓI CƯỚC CÓ THỂ ĐĂNG KÝ (GH)"
          // subheader="Giờ tra cứu 7:00 - 22:00 | Số lần tra cứu: 4 lần mỗi giờ"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            name="phone"
            required
            onChange={handleChange}
            value={values.phone}
            variant="outlined"
          />

        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          {isGrantPermission(permissions, ['tra_cuu_2_2']) && <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            disabled={clickTraCuu}
          >
            Tra cứu
          </Button>}
        </Box>
        {cusInfo && typeof cusInfo.Get_KM_KN !== 'undefined' && cusInfo.Get_KM_KN && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>

              <TableRow>
                <TableCell width="40%">
                  KẾT QUẢ
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Đối tượng chương trình
                  </Typography>
                </TableCell>
                <TableCell>
                  {cusInfo && typeof cusInfo.Get_KM_KN !== 'undefined' && cusInfo.Get_KM_KN && typeof cusInfo.Get_KM_KN.KhuyenMai !== 'undefined' && cusInfo.Get_KM_KN.KhuyenMai ? cusInfo.Get_KM_KN.KhuyenMai.split(/\s/g).join("").split(/,/g).join(", ") : ''}
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </Box>}
      </Card>}
    </form>
  )
};

export default CustomerLookupInfo3;
