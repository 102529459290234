import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { InviteAction } from 'src/actions/invite.action';
import { InviteTypes } from 'src/utils/constants';

import { Search as SearchIcon } from 'react-feather';

import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuItem,
} from '@material-ui/core';

import Select from 'react-select';

import { Moigoi1543Action } from 'src/actions/moigoi1543.action';

const InvitaionRenew = (props) => {

  const dispatch = useDispatch()
  const { renew } = useSelector(state => state.invite)
  const { items, total } = useSelector(state => state.moigoi1543)
  const { list_1543_items, list_1543_total } = useSelector(state => state.moigoi1543)
  const [maMoiGoi, setMaMoiGoi] = useState();
  const [values, setValues] = useState({
    package: '',
    phone: ''
  });

  useEffect(() => {
    setValues({
      package: '',
      phone: '',
    })
  }, [list_1543_items])

  const handleChange = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
  };

  const handleChange1 = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  //GIA HẠN GÓI CƯỚC -> ĐĂNG KÝ
  const handleDK = () => {
    // dispatch(InviteAction.inviteRenew({
    //   type: InviteTypes.RENEW,
    //   phone: values.phone,
    //   package: values.package,
    //   command: "DK"
    // }))
    dispatch(Moigoi1543Action.moiGoiCuoc({
      phone: values.phone,
      package: values.package,
    }))
  }

  //GIA HẠN GÓI CƯỚC -> GIA HẠN
  const handleGH = () => {
    dispatch(InviteAction.inviteRenew({
      type: InviteTypes.RENEW,
      phone: values.phone,
      package: values.package,
      command: "GIAHAN"
    }))
  }

  useEffect(() => {
    dispatch(Moigoi1543Action.layGoiCuoc())

    const getMaMoiGoi = async () => {
      const maMoiGoi = await dispatch(Moigoi1543Action.getMaMoiGoi());
      if (maMoiGoi && maMoiGoi.result == 'success') {
        setMaMoiGoi(maMoiGoi.code)
      }
    };

    getMaMoiGoi();
  }, [])

  return (
    <>
      <div style={{ fontSize: '18px', padding: '15px 0' }}>Mã mời gói của bạn là <span style={{ fontSize: '24px', color: 'red', fontWeight: 'bold' }}>{maMoiGoi}</span></div>

      <form {...props}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ width: '100%', zIndex: '10' }} className="full-width-select">
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  height: 56,
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }),
                option: (provided) => ({
                  ...provided,
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }),
              }}
              options={items}
              getOptionLabel={(item) => `${item.DVU} - ${item.GIA}`}
              placeholder={values.package || "Chọn gói cước..."}
              components={{
                DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                IndicatorSeparator: null,
              }}
              value={values.package || null}
              onChange={(item) => handleChange('package', item.DVU)}
              isSearchable />
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            name="phone"
            onChange={handleChange1}
            required
            value={values.phone}
            variant="outlined" />
          <Button
            color="primary"
            variant="contained"
            disabled={!values.phone || !values.package}
            onClick={handleDK}
            style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
          >
            Mời đăng ký
          </Button>
          {1 < 0 && <Button
            color="primary"
            variant="contained"
            disabled={!values.phone || !values.package}
            onClick={handleGH}
            style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
          >
            Mời gia hạn
          </Button>}
        </Box>
      </form>
    </>
  );
};

export default InvitaionRenew;
