import { Helmet } from 'react-helmet';
import VinareportLookupDetail
  from 'src/components/vinareport-lookup-detail/VinareportLookupDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import VinareportListToolbar from '../components/vinareport-lookup-detail/VinareportListToolbar';

const VinareportLookup = () => (
  <>
    <Helmet>
      <title>Báo cáo chi tiết</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <VinareportListToolbar />
        <Box sx={{ pt: 3 }}>
          <VinareportLookupDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default VinareportLookup;
